
import Header from '@/components/base/Header.vue'
import Footer from '@/components/base/Footer.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SettingsOverview',
  components: { Footer, Header },
  data: function () {
    return {
      isAdmin: !!localStorage.getItem('isAdmin')
    }
  }
})
