
import { defineComponent, ref } from 'vue'
import { errorHelper, getApplicationPeriod, getApplicationTime, getAreaOfCompany, getDate } from '@/utils'
import { Application, ApplicationPeriod } from '@/types/descript_application_portal_rest'
import MainOverviewContainer from '@/components/base/MainOverviewContainer.vue'
import ApplicationStateComponent from '@/components/application/ApplicationStateComponent.vue'
import { getEmptyState } from '@/store/state'
import Modal from '@/components/modal/Modal.vue'
import ModalHeader from '@/components/modal/ModalHeader.vue'
import ModalTitle from '@/components/modal/ModalTitle.vue'
import ModalBody from '@/components/modal/ModalBody.vue'
import ModalFooter from '@/components/modal/ModalFooter.vue'
import Button from '@/components/base/Button.vue'

export default defineComponent({
  name: 'UserApplicationOverview',
  components: {
    MainOverviewContainer,
    ApplicationStateComponent,
    Button,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalBody,
    ModalFooter
  },
  setup () {
    const requestApplicationModal = ref<InstanceType<typeof Modal>>()
    return { requestApplicationModal }
  },
  computed: {
    activeLocale: function (): string {
      return this.$i18n.locale
    },
    applicationList: function (): Array<Application> {
      return this.$store.state.allApplications
    },
    periodList: function (): Record<number, ApplicationPeriod> {
      return this.$store.state.applicationPeriods
    },
    currentApplicationPeriod: function (): ApplicationPeriod | null {
      return this.$store.state.currentApplicationPeriod
    }
  },
  data: function () {
    return {
      isLoaded: false,
      hasCurrentApplication: false
    }
  },
  created: async function () {
    this.$store.replaceState(getEmptyState())
    await this.$store.dispatch('getCurrentApplicationPeriod').catch(errorHelper(this.$store))
    await this.$store.dispatch('getAllApplications').catch(errorHelper(this.$store))
    const periods: Record<number, ApplicationPeriod> = {}
    let period
    for (const application of this.applicationList) {
      if (!(application.application_period in periods)) {
        period = await this.$store.dispatch('getSpecificApplicationPeriod', {
          period: application.application_period,
          setCurrent: false
        })
        periods[application.application_period] = period
      }
    }
    if (this.currentApplicationPeriod && this.currentApplicationPeriod.id in periods) {
      this.hasCurrentApplication = true
    }
    this.$store.commit('setAllApplicationPeriods', periods)
    this.isLoaded = true
  },
  methods: {
    getAreaOfCompany,
    getApplicationTime,
    getDate,
    getApplicationPeriod,
    requestNewApplication: async function (currentApplicationPeriodId: number, applicationToCopyFrom?: number) {
      const newApplication = await this.$store.dispatch('requestApplication', {
        currentApplicationPeriodId: currentApplicationPeriodId,
        applicationToCopyFrom: applicationToCopyFrom
      }).catch(errorHelper(this.$store))
      if (newApplication) {
        this.$router.push(`/application/${newApplication.id}`)
      }
    },
    showRequestModal: function () {
      if (this.requestApplicationModal) {
        this.requestApplicationModal.show()
      }
    },
    getReversedApplicationList: function () {
      return JSON.parse(JSON.stringify(this.applicationList)).reverse()
    }
  }
})
