
import { defineComponent } from 'vue'
import { Tooltip } from 'bootstrap'
import BadgeRound from '@/components/base/BadgeRound.vue'
import { OrganizationCertificateValidity } from '@/types/descript_application_portal_rest'

export default defineComponent({
  name: 'ValidityOfCertificateComponent',
  components: {
    BadgeRound
  },
  props: {
    validity: {
      required: true,
      type: Number
    },
    id: {
      required: true,
      type: Number
    }
  },
  data: function () {
    return {
      tooltipTriggerList: [],
      OrganizationCertificateValidity
    }
  },
  mounted: function () {
    this.tooltipTriggerList = [].slice.call(document.querySelectorAll(`[id="tooltip-${this.id}"`))
    this.tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    })
  },
  unmounted: function () {
    this.tooltipTriggerList.map(function (tooltipTriggerEl) {
      const tooltipEl = Tooltip.getInstance(tooltipTriggerEl)
      if (tooltipEl !== null) {
        tooltipEl.dispose()
      }
    })
  }
})
