
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Button',
  props: {
    btnStyle: {
      type: String
    },
    btnType: {
      type: String
    },
    btnTitle: {
      type: String
    },
    btnDisabled: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: String
    },
    iconOnly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    renderShowIcon: function (icon: string): string {
      return 'b-icon-' + icon
    }
  }
})
