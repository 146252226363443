
import { defineComponent } from 'vue'
import { Toast } from 'bootstrap'

export default defineComponent({
  name: 'App',
  data: function () {
    return {
      toast: null as Toast | null
    }
  },
  mounted: function () {
    const localToast = document.querySelectorAll('#updateToast')[0]
    this.toast = new Toast(localToast, { autohide: false })
    window.addEventListener('vueVersionUpdated', () => {
      if (this.toast !== null) {
        this.toast.show()
      }
    }, { once: true })
  },
  methods: {
    reloadSite () {
      window.location.reload()
    }
  },
  unmounted: function () {
    if (this.toast !== null) {
      this.toast.dispose()
    }
  }
})
