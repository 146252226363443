
import { defineComponent } from 'vue'
import { Modal as BootstrapModal } from 'bootstrap'

export default defineComponent({
  name: 'Modal',
  props: {
    id: {
      type: String,
      required: true
    },
    sizeClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      modalInstance: null as BootstrapModal | null
    }
  },
  methods: {
    show () {
      if (this.modalInstance) {
        this.modalInstance.show()
      }
    },
    hide () {
      if (this.modalInstance) {
        this.modalInstance.hide()
      }
    },
    dispose () {
      if (this.modalInstance) {
        this.modalInstance.dispose()
      }
    }
  },
  mounted: function () {
    this.modalInstance = new BootstrapModal(this.$el)
  },
  unmounted: function () {
    this.dispose()
  }
})
