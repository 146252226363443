import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-lg-10 text-center" }
const _hoisted_4 = { class: "lead" }
const _hoisted_5 = { class: "lead" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 0,
  class: "card"
}
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "table table-hover" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["data-bs-content"]
const _hoisted_13 = {
  class: "btn btn-primary",
  disabled: ""
}
const _hoisted_14 = { key: 1 }
const _hoisted_15 = {
  key: 1,
  class: "alert alert-warning"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_SubHeader = _resolveComponent("SubHeader")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, { position: "" }),
    (_ctx.hasCurrentApplication)
      ? (_openBlock(), _createBlock(_component_SubHeader, {
          key: 0,
          "link-to-overview": `/application/${_ctx.applicationId}`
        }, null, 8, ["link-to-overview"]))
      : _createCommentVNode("", true),
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('meta.pageTitles.actions_overview')), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('text.actions_headline_subtitle')), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('text.actions_headline_sub_subtitle')), 1),
          _createElementVNode("p", {
            innerHTML: _ctx.$t('text.actions_headline_min_max_explanation', {
              min: _ctx.minMaxActions.min,
              max: _ctx.minMaxActions.max,
              current: _ctx.currentActions.length
            })
          }, null, 8, _hoisted_6),
          (_ctx.hasCurrentApplication)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                innerHTML: _ctx.$t('text.actions_headline_min_max_links', _ctx.getFieldOfActionActionBorders(_ctx.currentApplication.organization))
              }, null, 8, _hoisted_7))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.hasCurrentApplication)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_SearchInput, {
                label: _ctx.$t('text.search'),
                placeholder: _ctx.$t('search.actions'),
                name: "search",
                onDoSearch: _ctx.doSearch,
                onResetSearch: _ctx.resetSearch
              }, null, 8, ["label", "placeholder", "onDoSearch", "onResetSearch"]),
              _createElementVNode("table", _hoisted_10, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('text.title')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('actions.connections')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('text.item_actions')), 1)
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentActions, (action) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: action.id + _ctx.activeLocale
                    }, [
                      _createElementVNode("td", null, [
                        _createVNode(_component_router_link, {
                          to: { name: 'ApplicationActionDetail', params: { id: action.id} }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getActionTitle(action)), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]),
                      _createElementVNode("td", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(action.field_of_action, (foa) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: foa,
                            class: "badge badge-info rounded-pill ms-1"
                          }, _toDisplayString(_ctx.$t('fields_of_action.short_name', { number: _ctx.fieldsOfActions[foa] })), 1))
                        }), 128))
                      ]),
                      _createElementVNode("td", null, [
                        (!_ctx.applicationInReadonlyMode(_ctx.currentApplication, _ctx.currentApplicationPeriod))
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              href: "",
                              onClick: _withModifiers(($event: any) => (_ctx.deleteAction(action)), ["prevent"])
                            }, _toDisplayString(_ctx.$t('text.delete')), 9, _hoisted_11))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  }), 128))
                ])
              ]),
              (_ctx.currentActionsExceedsMax())
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    "data-bs-toggle": "popover",
                    "data-bs-trigger": "hover",
                    "data-bs-content": _ctx.$t('actions.to_much_actions'),
                    class: "action-overview-popover"
                  }, [
                    _createElementVNode("button", _hoisted_13, _toDisplayString(_ctx.$t('actions.create_new_action')), 1)
                  ], 8, _hoisted_12))
                : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createVNode(_component_router_link, {
                      class: "btn btn-primary",
                      disabled: _ctx.applicationInReadonlyMode(_ctx.currentApplication, _ctx.currentApplicationPeriod),
                      to: { name: 'ApplicationActionAdd', params: { appId: _ctx.currentApplication.id }}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('actions.create_new_action')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "to"])
                  ]))
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.$t('text.no_current_application')), 1))
    ]),
    _createVNode(_component_Footer)
  ], 64))
}