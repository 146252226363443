
import { defineComponent, ref } from 'vue'
import Modal from '@/components/modal/Modal.vue'
import ModalHeader from '@/components/modal/ModalHeader.vue'
import ModalTitle from '@/components/modal/ModalTitle.vue'
import ModalBody from '@/components/modal/ModalBody.vue'
import { Form, FormActions } from 'vee-validate'
import FormInput from '@/components/forms/FormInput.vue'
import FormFileInput from '@/components/forms/FormFileInput.vue'
import * as yup from 'yup'
import ModalFooter from '@/components/modal/ModalFooter.vue'
import { AlertType } from '@/types/internal'
import { errorHelper } from '@/utils'

interface IncompleteFileModel {
  name: string,
  // eslint-disable-next-line camelcase
  uploaded_file: Array<File>
}

export default defineComponent({
  name: 'ApplicationFormFileUpload',
  components: {
    FormInput,
    FormFileInput,
    ModalBody,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    Form
  },
  props: {
    applicationFormId: {
      type: Number,
      required: true
    }
  },
  setup () {
    // refs to child components in a TypeScript compatible way,
    // see https://v3.vuejs.org/guide/typescript-support.html#typing-refs.
    const uploadApplicationFormFileModal = ref<InstanceType<typeof Modal>>()
    const inputFile = ref<InstanceType<typeof FormFileInput>>()
    const uploadForm = ref<InstanceType<typeof Form>>()

    return { uploadApplicationFormFileModal, inputFile, uploadForm }
  },
  data: function () {
    const uploadSchema = yup.object().shape({
      name: yup.string().required(this.$t('form_validation.required')),
      uploaded_file: yup.mixed().required(this.$t('form_validation.required'))
    })
    return {
      uploadSchema: uploadSchema,
      uploadProcessing: false
    }
  },
  methods: {
    show () {
      if (this.uploadApplicationFormFileModal) {
        this.uploadApplicationFormFileModal.show()
      }
    },
    hideModal () {
      if (this.uploadForm) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.uploadForm.resetForm()
      }
      if (this.inputFile) {
        this.inputFile.reset()
      }
      if (this.uploadApplicationFormFileModal) {
        this.uploadApplicationFormFileModal.hide()
      }
    },
    uploadFile: async function (values: IncompleteFileModel, actions: FormActions<Record<string, unknown>>) {
      const formData = new FormData()
      formData.append('application_form', this.applicationFormId.toString())
      formData.append('name', values.name)
      formData.append('uploaded_file', values.uploaded_file[0])
      this.uploadProcessing = true
      await this.$store.dispatch('uploadApplicationFormFile', formData).then(
        () => {
          this.$store.commit('addImmediateAlert',
            {
              msg: 'actions.file_uploaded',
              type: AlertType.Success
            })
          actions.resetForm()
          if (this.uploadApplicationFormFileModal) {
            this.$emit('fileUploaded')
            this.uploadProcessing = false
            if (this.inputFile) {
              this.inputFile.reset()
            }
            this.uploadApplicationFormFileModal.hide()
          }
        }, errorHelper(this.$store, (error) => {
          actions.setErrors(error.response.data)
          this.uploadProcessing = false
        }))
    }
  }
})
