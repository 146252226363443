
import { defineComponent, PropType } from 'vue'
import { ApplicationAction, FieldOfAction, FieldOfActionType } from '@/types/descript_application_portal_rest'
import { getTitleForFieldOfActionType } from '@/utils'

export default defineComponent({
  name: 'ReadOnlyAction',
  props: {
    applicationAction: {
      required: true,
      type: Object as PropType<ApplicationAction>
    },
    fieldsOfAction: {
      required: true,
      type: Object as PropType<Array<FieldOfAction>>
    },
    isPrint: {
      default: false
    }
  },
  methods: {
    getFieldTitle: function (fieldType: FieldOfActionType): string {
      return getTitleForFieldOfActionType(fieldType)
    },
    replace_linebreaks: function (text: string) {
      return text.replaceAll('\n', '<br>')
    }
  }
})
