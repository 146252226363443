
import { defineComponent } from 'vue'
import Header from '@/components/base/Header.vue'
import Footer from '@/components/base/Footer.vue'
import { Form, FormActions } from 'vee-validate'
import * as yup from 'yup'
import FormInput from '@/components/forms/FormInput.vue'
import http from '@/http-common'
import { AlertType } from '@/types/internal'
import { errorHelper, handleLogout } from '@/utils'

export default defineComponent({
  name: 'ChangePassword',
  components: {
    Footer,
    Header,
    Form,
    FormInput
  },
  data: function () {
    const schema = yup.object({
      new_password: yup.string()
        .required(this.$t('form_validation.required')),
      re_new_password: yup.string()
        .required(this.$t('form_validation.required'))
        .oneOf([yup.ref('new_password')], this.$t('user_settings.new_password_miss_match'))
    })

    return {
      schema: schema,
      current_password: '',
      new_password: '',
      re_new_password: ''
    }
  },
  methods: {
    changePassword (values: Record<string, string>, actions: FormActions<Record<string, unknown>>) {
      const data = {
        current_password: values.current_password,
        new_password: values.new_password,
        re_new_password: values.re_new_password
      }
      http.post('auth/users/set_password/', data).then(
        () => {
          handleLogout(this.$store)
          this.$store.commit(
            'addPendingAlert',
            {
              msg: this.$t('user_settings.new_password_success'),
              type: AlertType.Success
            })

          this.$router.push('/auth')
        }, errorHelper(this.$store, (error) => {
          let foundProperErrorMsg = false
          if ('current_password' in error.response.data) {
            actions.setFieldError('current_password', error.response.data.current_password)
            foundProperErrorMsg = true
          }
          if (!foundProperErrorMsg) {
            this.$store.commit('addImmediateAlert', { msg: error.toString(), type: AlertType.Danger })
          }
        }))
        .catch((response) => {
          this.$store.commit('addImmediateAlert', { msg: response.message, type: AlertType.Danger })
        })
    }
  }
})
