
import { defineComponent } from 'vue'
import SVGArrowLeft from '@/components/svg/SVGArrowLeft.vue'
import {
  Application,
  ApplicationForm,
  ApplicationPeriod, FieldOfAction, FieldOfActionType,
  FormType,
  Organization
} from '@/types/descript_application_portal_rest'
import {
  errorHelper,
  getCorrespondingFormForFormType,
  getShortNameForFieldOfAction,
  getApplicationPeriod
} from '@/utils'
import ApplicationStateComponent from '@/components/application/ApplicationStateComponent.vue'
import Dropdown from '@/components/base/Dropdown.vue'
import BadgeRound from '@/components/base/BadgeRound.vue'
import { i18n } from '@/i18n'

export default defineComponent({
  name: 'SubHeader',
  components: {
    ApplicationStateComponent,
    Dropdown,
    SVGArrowLeft,
    BadgeRound
  },
  props: {
    linkToOverview: {
      type: String
    },
    linkToOverviewTitle: {
      type: String,
      default: i18n.global.t('links.back_to_overview')
    }
  },
  data: function () {
    return {
      hasCurrentApplication: false,
      localFormTypes: FormType
    }
  },
  computed: {
    application (): Application | null {
      return this.$store.state.currentApplication
    },
    organization (): Organization | null {
      return this.$store.state.organization
    },
    applicationForms (): Array<ApplicationForm> {
      return this.$store.state.applicationForms
    },
    periodOfApplication (): ApplicationPeriod | null {
      return this.$store.state.periodOfApplication
    },
    fieldsOfActions: function (): Array<FieldOfAction> {
      return this.$store.state.fieldsOfAction
    }
  },
  methods: {
    getFormIdForFormType: function (formType: FormType) {
      return getCorrespondingFormForFormType(formType, this.applicationForms).id
    },
    getCurrentApplicationPeriod: function (applicationPeriod: ApplicationPeriod | null) {
      return getApplicationPeriod(applicationPeriod)
    },
    getShortNameOfField: function (fieldType: FieldOfActionType) {
      return getShortNameForFieldOfAction(fieldType)
    }
  },
  created: async function () {
    if (this.$store.state.applicationForms.length !== 3) {
      await this.$store.dispatch('getFormsForApplication').catch(errorHelper(this.$store))
    }

    if (this.$store.state.fieldsOfAction.length === 0) {
      await this.$store.dispatch('getFieldOfActionForApplication').catch(errorHelper(this.$store))
    }

    if (this.$store.state.currentApplication) {
      this.hasCurrentApplication = true
    }
  }
})
