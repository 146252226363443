<template>
  <div class="card text-center h-100">
    <div class="card-body">
      <h2 v-if="title"
          class="h3">
        {{ title }}
      </h2>
      <p v-if="text"
         v-html="text"></p>
    </div>
    <div v-if="link"
         class="card-footer">
      <a v-if="isExternalUrl"
         :href="link"
         target="_blank"
         rel="noopener"
         class="btn btn-outline-primary">
        {{ $t('links.read_more') }}
      </a>
      <router-link v-else
                   :to="link"
                   class="btn btn-outline-primary">
        {{ $t('links.read_more') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Card',
  props: {
    title: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: false
    },
    link: {
      type: String,
      required: false
    },
    isExternalUrl: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style scoped>

</style>
