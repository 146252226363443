
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true
    },
    labelLink: {
      type: String,
      required: false
    },
    labelLinkText: {
      type: String,
      required: false
    },
    labelEnd: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    helpText: String
  },
  setup (props) {
    const {
      checked,
      errorMessage,
      handleChange
    } = useField(props.name, undefined, {
      type: 'checkbox',
      checkedValue: 'on',
      uncheckedValue: ''
    })

    return {
      checked,
      errorMessage,
      handleChange
    }
  },
  computed: {
    helpId (): string {
      return this.name + 'Help'
    }
  }
})
