
import { defineComponent } from 'vue'
import { Form } from 'vee-validate'
import FormInput from '@/components/forms/FormInput.vue'
import Alerts from '@/components/base/Alerts.vue'
import * as yup from 'yup'
import http from '@/http-common.ts'
import { AlertType } from '@/types/internal'
import { errorHelper, handleLogout } from '@/utils'
import MainCard from '@/components/base/MainCard.vue'
import LogoTitle from '@/components/base/LogoTitle.vue'

export default defineComponent({
  name: 'ResetPassword',
  components: {
    MainCard,
    Form,
    FormInput,
    Alerts,
    LogoTitle
  },
  data: function () {
    const schema = yup.object({
      new_password: yup.string()
        .required(this.$t('form_validation.required')),
      re_new_password: yup.string()
        .required(this.$t('form_validation.required'))
        .oneOf([yup.ref('new_password')], this.$t('auth.new_password_miss_match'))
    })

    return {
      schema: schema,
      new_password: '',
      re_new_password: ''
    }
  },
  methods: {
    saveNewPassword (values: Record<string, string>) {
      const data = {
        uid: this.$route.params.uid,
        token: this.$route.params.token,
        new_password: values.new_password,
        re_new_password: values.re_new_password
      }
      http.post('auth/users/reset_password_confirm/', data).then(
        () => {
          handleLogout(this.$store)

          this.$store.commit(
            'addPendingAlert',
            {
              msg: 'auth.new_password_success',
              type: AlertType.Success
            })

          this.$router.push('/auth')
        }, errorHelper(this.$store, (error) => {
          let foundProperErrorMsg = false
          if ('non_field_errors' in error.response.data) {
            foundProperErrorMsg = true
          }
          if ('token' in error.response.data) {
            this.$store.commit('addImmediateAlert', {
              msg: error.response.data.token.toString(),
              type: AlertType.Danger
            })
            foundProperErrorMsg = true
          }
          if (!foundProperErrorMsg) {
            this.$store.commit('addImmediateAlert', { msg: error.toString(), type: AlertType.Danger })
          }
        }))
        .catch((response) => {
          this.$store.commit('addImmediateAlert', { msg: response.message, type: AlertType.Danger })
        })
    }
  }
})
