import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "lead" }
const _hoisted_2 = {
  key: 0,
  class: "card"
}
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-3" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "table table-hover" }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterBar = _resolveComponent("FilterBar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ValidityOfCertificateComponent = _resolveComponent("ValidityOfCertificateComponent")!
  const _component_MainOverviewContainer = _resolveComponent("MainOverviewContainer")!

  return (_openBlock(), _createBlock(_component_MainOverviewContainer, null, {
    title: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('meta.pageTitles.admin_organization_overview')), 1),
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('text.admin_applications_headline_subtitle')), 1)
    ]),
    content: _withCtx(() => [
      (_ctx.isLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_FilterBar, {
                  identifier: "organization",
                  availableFilters: _ctx.availableFilters,
                  activeFilters: _ctx.activeFilters,
                  onRemoveFilter: _ctx.removeFilter,
                  onAddFilter: _ctx.addFilter
                }, null, 8, ["availableFilters", "activeFilters", "onRemoveFilter", "onAddFilter"]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("a", {
                    href: "",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.resetAllFilters && _ctx.resetAllFilters(...args)), ["prevent"])),
                    class: "text-muted fs-5"
                  }, _toDisplayString(_ctx.$t('links.reset_all_filter')), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("table", _hoisted_7, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('application.organization')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('application.organization_type')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('application.validity_award')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('application.newest_application')), 1)
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationList, (organization) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: organization.id + _ctx.activeLocale
                    }, [
                      _createElementVNode("td", null, [
                        _createVNode(_component_router_link, {
                          to: { name: 'AdminApplicationOverview', params: { id: organization.id }}
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(organization.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t(_ctx.getAreaOfCompany(organization))), 1),
                      _createElementVNode("td", null, [
                        _createVNode(_component_ValidityOfCertificateComponent, {
                          id: organization.id,
                          validity: organization.current_certificate_is_valid
                        }, null, 8, ["id", "validity"])
                      ]),
                      _createElementVNode("td", null, [
                        (organization.latest_application_id !== 0)
                          ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 0,
                              to: { name: 'ApplicationOverview', params: { id: organization.latest_application_id }}
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getApplicationPeriod(organization.latest_application_application_period)), 1)
                              ]),
                              _: 2
                            }, 1032, ["to"]))
                          : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t('text.no_application')), 1))
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}