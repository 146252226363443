import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["name", "id", "aria-describedby", "placeholder", "value"]
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["mb-3", _ctx.label ? 'form-floating form-floating-area': ''])
  }, [
    _createElementVNode("textarea", {
      class: _normalizeClass(["form-control", _ctx.elementStyle + _ctx.errorMessage ? ' is-invalid': '']),
      name: _ctx.name,
      id: _ctx.name,
      "aria-describedby": _ctx.helpId,
      placeholder: _ctx.label ? _ctx.label: _ctx.placeholder,
      value: _ctx.inputValue,
      style: _normalizeStyle('height: ' + _ctx.height + 'px'),
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args))),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args)))
    }, null, 46, _hoisted_1),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.name,
          class: _normalizeClass(_ctx.labelStyle)
        }, _toDisplayString(_ctx.label), 11, _hoisted_2))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", { class: "invalid-feedback" }, _toDisplayString(_ctx.errorMessage), 513), [
      [_vShow, !!_ctx.errorMessage]
    ]),
    (_ctx.helpText)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          id: _ctx.helpId,
          class: "form-text"
        }, _toDisplayString(_ctx.helpText), 9, _hoisted_3))
      : _createCommentVNode("", true)
  ], 2))
}