
import { defineComponent } from 'vue'
import { AlertType } from '@/types/internal'

export default defineComponent({
  name: 'Alerts',
  props: {
    sticky: {
      type: String,
      default: ''
    }
  },
  data: function () {
    const currentAlerts = []

    // Iterate the pending alerts of the global store to show
    // any message a former view had created which is then shown
    // on the new view which the other redirected to.
    for (let i = 0; i < this.$store.state.pendingAlerts.length; i++) {
      currentAlerts.push(this.$store.state.pendingAlerts[i])
    }

    // When the pending alerts have been grabbed then clear the
    // global store so that the alerts are not repeated over and
    // over again.
    this.$store.commit('clearPendingAlerts')

    return {
      currentAlerts: currentAlerts,
      unsubscribeMutationWatcher: null as (() => void) | null
    }
  },
  computed: {
    activeLocale (): string {
      return this.$i18n.locale
    }
  },
  methods: {
    removeAlert: function (alert: { msg: string; type: AlertType }) {
      const index = this.currentAlerts.indexOf(alert)
      if (index > -1) {
        this.currentAlerts.splice(index, 1)
      }
    }
  },
  created: function () {
    this.unsubscribeMutationWatcher = this.$store.subscribe((mutation) => {
      if (mutation.type === 'addImmediateAlert') {
        // Grab the alert from the global store
        for (let i = 0; i < this.$store.state.immediateAlerts.length; i++) {
          this.currentAlerts.push(this.$store.state.immediateAlerts[i])
        }

        // Clear global store
        this.$store.commit('clearImmediateAlerts')
      } else if (mutation.type === 'clearAndDismissImmediateAlerts') {
        this.currentAlerts.splice(0, this.currentAlerts.length)
      }
    })
  },
  beforeUnmount: function () {
    if (this.unsubscribeMutationWatcher !== null) {
      this.unsubscribeMutationWatcher()
    }
  }
})
