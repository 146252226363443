import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-8" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-2" }
const _hoisted_6 = { class: "col-8" }
const _hoisted_7 = { class: "card" }
const _hoisted_8 = { class: "col-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h1", {
            class: _normalizeClass(`text-center ${_ctx.titleCls}`)
          }, _toDisplayString(_ctx.title), 3),
          _createElementVNode("p", {
            class: _normalizeClass(`text-center ${_ctx.subtitleCls}`)
          }, _toDisplayString(_ctx.subtitle), 3)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "form-fieldsets-nav")
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: _normalizeClass(["card-body", [_ctx.isJsonForm ? 'json-form' : '']])
            }, [
              _renderSlot(_ctx.$slots, "form")
            ], 2)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "form-progress")
        ])
      ])
    ]),
    _createVNode(_component_Footer, { position: "mb-7" })
  ], 64))
}