import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "container"
}
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-lg-8 text-center" }
const _hoisted_4 = { class: "row justify-content-center" }
const _hoisted_5 = { class: "col-12 col-xl-9" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-6 col-xl-7 position-relative" }
const _hoisted_9 = { class: "fw-normal text-muted mb-3" }
const _hoisted_10 = { class: "h3" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "col-6 col-xl-5 d-flex align-items-center" }
const _hoisted_13 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_SubHeader = _resolveComponent("SubHeader")!
  const _component_Alerts = _resolveComponent("Alerts")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ProgressWheel = _resolveComponent("ProgressWheel")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, { position: "" }),
    (_ctx.applicationAndFieldsAreLoaded)
      ? (_openBlock(), _createBlock(_component_SubHeader, {
          key: 0,
          "link-to-overview": `/application/${_ctx.applicationId}`
        }, null, 8, ["link-to-overview"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Alerts),
    (_ctx.applicationAndFieldsAreLoaded)
      ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h1", null, _toDisplayString(_ctx.$t('meta.pageTitles.field_of_action_overview')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('fields_of_action.subtitle', { count: _ctx.fieldsOfActions.length })), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldsOfActions, (field, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: field.id,
                  class: _normalizeClass(["card", [index + 1 !== _ctx.fieldsOfActions.length ? 'mb-4' : '']])
                }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t('fields_of_action.field_no', { number: index + 1 })), 1),
                        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t(_ctx.getFieldTitle(field.field_of_action_type))), 1),
                        (_ctx.showErrorMessage(field))
                          ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.$t('fields_of_action.action_count_wrong', { min: _ctx.constraints.min, max: _ctx.constraints.max, current: field.actions.length })), 1))
                          : _createCommentVNode("", true),
                        _createVNode(_component_router_link, {
                          to: { name: 'FieldOfActionDetails', params: { id: field.id }},
                          class: "btn btn-primary stretched-link"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('meta.pageTitles.field_of_action_detail')), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_ProgressWheel, {
                          state: _ctx.getStateOfField(field),
                          class: "mb-0"
                        }, null, 8, ["state"])
                      ])
                    ])
                  ])
                ], 2))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.$t('text.no_current_application')), 1)),
    _createVNode(_component_Footer)
  ], 64))
}