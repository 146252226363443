
import { defineComponent } from 'vue'
import Button from '@/components/base/Button.vue'

export default defineComponent({
  name: 'SearchInput',
  components: {
    Button
  },
  props: {
    label: String,
    name: {
      type: String,
      required: true
    },
    initial: String,
    elementStyle: String,
    labelStyle: String,
    helpText: String,
    placeholder: String,
    showResetBtn: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      term: this.initial
    }
  },
  computed: {
    helpId (): string {
      return this.name + 'Help'
    }
  },
  methods: {
    doSearch: function () {
      if (this.term) {
        this.$emit('doSearch', this.term)
      } else {
        this.$emit('resetSearch')
      }
    },
    resetSearch: function () {
      this.term = ''
      this.$emit('resetSearch')
    }
  }
})
