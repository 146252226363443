import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { store } from '@/store'
import { i18n } from '@/i18n'
import '@/assets/scss/style.scss'
import { isProduction, matomoConfiguration, sentryConfiguration } from '@/config/config'
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue'
// ignoring the Vue matomo import is necessary since matomo doesn't support typescript and don't plan
// on using it 'anytime soon' (https://issues.matomo.org/14472)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueMatomo from 'vue-matomo'
import * as Sentry from '@sentry/browser'

Sentry.init(sentryConfiguration)

function captureException (event: ErrorEvent | PromiseRejectionEvent | unknown) {
  console.log(event)
  Sentry.setExtra('username', localStorage.getItem('username'))
  Sentry.captureException(event)
}

window.addEventListener('error', captureException)
window.addEventListener('unhandledrejection', captureException)

const app = createApp(App).use(store).use(router).use(i18n).use(BootstrapIconsPlugin)

if (isProduction) {
  app.use(VueMatomo, matomoConfiguration)
}

app.config.errorHandler = captureException

app.mount('#app')
