
import { defineComponent } from 'vue'
import LocaleChooser from '@/components/base/LocaleChooser.vue'

export default defineComponent({
  name: 'Footer',
  components: {
    LocaleChooser
  },
  props: {
    position: String,
    noPrint: {
      type: Boolean,
      default: false
    }
  }
})
