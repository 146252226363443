import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('user_settings.user_account')), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('user_settings.change_user_data')), 1),
                    _createVNode(_component_router_link, {
                      to: "/organization",
                      class: _normalizeClass('btn btn-outline-primary' + [!!_ctx.isAdmin ? ' disabled' : ''])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('text.continue')), 1)
                      ]),
                      _: 1
                    }, 8, ["class"])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('user_settings.change_password')), 1),
                    _createVNode(_component_router_link, {
                      to: "/user_settings/change_password",
                      class: "btn btn-outline-primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('text.continue')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Footer)
  ], 64))
}