import { AlertType, Filter, FilterGroupData, FormErrorState } from '@/types/internal'
import router from '@/router'
import {
  Application,
  ApplicationForm,
  ApplicationPeriod,
  ApplicationState,
  FieldOfAction,
  FieldOfActionType,
  FormType,
  getPlaceholderApplicationForm,
  Organization,
  OrganizationSize,
  OrganizationType
} from '@/types/descript_application_portal_rest'
import { captureException } from '@sentry/browser'
import { getEmptyState, State } from '@/store/state'
import { Store } from 'vuex'
import { i18n } from '@/i18n'

export function isAuthenticated (): boolean {
  return localStorage.getItem('authToken') !== null
}

/**
 * General Error helper. This function will handle basic error Server responses like being not authorized or 500.
 * For non basic errors a callback is invoked when defined. This way error handling can happen in the components a
 * request is made or store actions are dispatched.
 * @example
 * store.dispatch('...').then(undefined, // success handling is optional
 *   errorHelper((error) => {
 *       // error handling here
 *    }
 *  ))
 * http.post('...').then(
          () => {
            // success
          }, errorHelper((error) => {
            // error handling of errors which weren't caught by the helper
          }
          )).catch(
          (response) => {
            // catching of js-errors
          }
        )
 */
export function errorHelper (store: Store<State>, callback?: (error: any) => void, statusCodesToPassThrough?: Array<number>) {
  return (error: any) => {
    if (error.message === 'Network Error') {
      store.commit('addImmediateAlert', {
        msg: 'error.network_error',
        type: AlertType.Danger
      })
      return
    }

    if (error.response && !statusCodesToPassThrough?.includes(error.response.status)) {
      if (error.response.status === 401) {
        handleLogout(store)
        router.push('/auth')
        store.commit('addPendingAlert', {
          msg: 'error.not_authenticated',
          type: AlertType.Danger
        })
        return
      }
      if (error.response.status === 403) {
        store.commit('addPendingAlert', {
          msg: 'error.permission_denied',
          type: AlertType.Danger
        })
        return
      }
      if (error.response.status === 500) {
        store.commit('addImmediateAlert', {
          msg: 'error.server_error',
          type: AlertType.Danger
        })
        return
      }
    }

    let nonFieldErrorsHandled = false

    if (error.response && error.response.data && 'non_field_errors' in error.response.data) {
      for (const nonFieldError of error.response.data.non_field_errors) {
        store.commit('addImmediateAlert', {
          msg: nonFieldError.toString(), type: AlertType.Danger
        })

        nonFieldErrorsHandled = true
      }
    }
    // We explicitly capture non field errors here to track them in Sentry and also give user feedback below.
    // This is necessary because most store action calls use this error helper and since it handles these errors we
    // would not see them in Sentry otherwise.
    if (!nonFieldErrorsHandled) {
      captureException(error)
    }
    // This is no ordinary error. Handle in origin, when callback is present.
    if (callback) {
      callback(error)
    } else if (!nonFieldErrorsHandled) {
      if (error.response && error.response.data && error.response.data.message) {
        store.commit('addImmediateAlert', {
          msg: error.response.data.message,
          type: AlertType.Danger
        })
      } else if (error.message) {
        store.commit('addImmediateAlert', { msg: error.message, type: AlertType.Danger })
      } else {
        store.commit('addImmediateAlert', { msg: error.toString(), type: AlertType.Danger })
      }
    }
  }
}

export function convertToTimezone (date: Date, timeZone: string): Date {
  // Based on https://stackoverflow.com/a/54127122 and it may fail on
  // older browsers which do not support parameters for toLocaleString
  // method. Unfortunately there is no available standard library
  // method to do the job directly and therefore this workaround by
  // utilizing toLocaleString is used.
  return new Date(date.toLocaleString('en-US', { timeZone: timeZone }))
}

export function datetimeLocalInputValue (date: Date): string {
  const year = date.getFullYear().toString().padStart(4, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hour = date.getHours().toString().padStart(2, '0')
  const minute = date.getMinutes().toString().padStart(2, '0')

  return `${year}-${month}-${day}T${hour}:${minute}`
}

export function getTitleForFieldOfActionType (fieldType: FieldOfActionType): string {
  switch (fieldType) {
    case FieldOfActionType.Structural:
      return 'fields_of_action.structural_description'
    case FieldOfActionType.EqualOpportunityEducation:
      return 'fields_of_action.equal_opportunity_education_description'
    case FieldOfActionType.Personal:
      return 'fields_of_action.personal_description'
    case FieldOfActionType.WorkLife:
      return 'fields_of_action.work_life_description'
    case FieldOfActionType.WorkStudyLife:
      return 'fields_of_action.work_study_life_description'
    case FieldOfActionType.Organization:
      return 'fields_of_action.organization_description'
    case FieldOfActionType.ProperBehaviour:
      return 'fields_of_action.proper_behaviour_description'
    default:
      return ''
  }
}

export function getCorrespondingFormForFormType (formType: FormType, applicationForms: Array<ApplicationForm>): ApplicationForm {
  let form = getPlaceholderApplicationForm()
  for (const applicationForm of applicationForms) {
    if (applicationForm.form_type === formType) {
      form = applicationForm
      break
    }
  }
  return form
}

export function getShortNameForFieldOfAction (fieldType: FieldOfActionType): string {
  switch (fieldType) {
    case FieldOfActionType.Structural:
      return 'fields_of_action.short.structural'
    case FieldOfActionType.Organization:
      return 'fields_of_action.short.organization'
    case FieldOfActionType.Personal:
      return 'fields_of_action.short.personal'
    case FieldOfActionType.WorkLife:
    case FieldOfActionType.WorkStudyLife:
      return 'fields_of_action.short.work_life'
    case FieldOfActionType.ProperBehaviour:
      return 'fields_of_action.short.proper_behaviour'
    case FieldOfActionType.EqualOpportunityEducation:
      return 'fields_of_action.short.equal_opportunity'
    default:
      return ''
  }
}

export function applicationInReadonlyMode (
  application: Application,
  applicationPeriod: ApplicationPeriod): boolean {
  return application.state === ApplicationState.ApplicationStateSuccessful ||
    application.state === ApplicationState.ApplicationStateWaitingForFeedback ||
    !!localStorage.getItem('isAdmin') || applicationPeriod.id !== application.application_period
}

export function getApplicationActionBorders (organization: Organization): Record<string, number> {
  if (organization.type === OrganizationType.OrganizationTypeUniversity ||
    organization.type === OrganizationType.OrganizationTypeResearch ||
    organization.type === OrganizationType.OrganizationTypeScienceOther) {
    if (organization.organization_size === OrganizationSize.OrganizationSizeSmall) {
      return { min: 6, max: 24 }
    } else if (organization.organization_size === OrganizationSize.OrganizationSizeMiddle) {
      return { min: 18, max: 36 }
    } else {
      return { min: 30, max: 48 }
    }
  }
  if (organization.organization_size === OrganizationSize.OrganizationSizeSmall) {
    return { min: 5, max: 20 }
  } else if (organization.organization_size === OrganizationSize.OrganizationSizeMiddle) {
    return { min: 15, max: 30 }
  } else {
    return { min: 25, max: 40 }
  }
}

export function getFieldOfActionActionBorders (organization: Organization): Record<string, number> {
  if (organization.organization_size === OrganizationSize.OrganizationSizeSmall) {
    return { min: 1, max: 4 }
  } else if (organization.organization_size === OrganizationSize.OrganizationSizeMiddle) {
    return { min: 3, max: 6 }
  } else {
    return { min: 5, max: 8 }
  }
}

export function isStatisticalFormOfScienceOrganization (applicationForm: ApplicationForm, organization: Organization): boolean {
  return applicationForm.form_type === FormType.FormTypeStatisticalInformation &&
    (organization.type === OrganizationType.OrganizationTypeUniversity ||
      organization.type === OrganizationType.OrganizationTypeScienceOther ||
      organization.type === OrganizationType.OrganizationTypeResearch)
}

export function getStateOfForm (form: ApplicationForm | FieldOfAction): FormErrorState {
  if (form.errors === null) {
    return FormErrorState.NotSaved
  } else if (form.errors.length === 0) {
    return FormErrorState.WithoutErrors
  }
  return FormErrorState.HasErrors
}

export function getStateOfFoa (form: FieldOfAction, constrains: Record<string, number>): FormErrorState {
  const state = getStateOfForm(form)
  if (state === FormErrorState.NotSaved || state === FormErrorState.HasErrors) {
    return state
  }
  if (form.actions.length > constrains.max || form.actions.length < constrains.min) {
    return FormErrorState.HasErrors
  }
  return state
}

export function getStateOfApplicationForm (form: ApplicationForm, organization: Organization, applicationFormLength: number): FormErrorState {
  const state = getStateOfForm(form)
  if (isStatisticalFormOfScienceOrganization(form, organization) && applicationFormLength === 0 && state !== FormErrorState.NotSaved) {
    return FormErrorState.HasErrors
  }
  return state
}

export function getApplicationPeriod (applicationPeriod: ApplicationPeriod | null): string {
  if (applicationPeriod) {
    const startDate = new Date(applicationPeriod.valid_from).getFullYear()
    const endDate = new Date(applicationPeriod.valid_to).getFullYear()
    return `${startDate} – ${endDate}`
  }
  return '-'
}

export function getDate (dateString: string, activeLocale: string): string {
  const date = new Date(dateString)

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }

  return date.toLocaleDateString(activeLocale, options)
}

export function getApplicationTime (applicationPeriod: ApplicationPeriod | null, activeLocale: string): string {
  /*
  Application deadlines are normally till the 30th of April. We assume the start date of an application period
  (in which the application can be submitted) to be the 1. of March and the end date as the first day of the
  ApplicationPeriod (which is the Certificate-period but named incorrectly)
   */
  if (applicationPeriod) {
    const startDate = new Date(applicationPeriod.valid_from)
    startDate.setFullYear(startDate.getFullYear() - 1, 4, 1)
    const endDate = new Date(applicationPeriod.valid_from)
    endDate.setDate(endDate.getDate())
    return `${getDate(startDate.toString(), activeLocale)} – ${getDate(endDate.toString(), activeLocale)}`
  }
  return '-'
}

export function handleLogout (store: Store<State>): void {
  localStorage.removeItem('authToken')
  localStorage.removeItem('username')
  localStorage.removeItem('isAdmin')
  localStorage.removeItem('applicationId')
  store.replaceState(getEmptyState())
}

export function getAreaOfCompany (organization: Organization | null): string {
  if (organization) {
    switch (organization.type) {
      case OrganizationType.OrganizationTypeAdministration:
        return 'text.orga_type_admin'
      case OrganizationType.OrganizationTypeAssociation:
        return 'text.orga_type_association'
      case OrganizationType.OrganizationTypeIndustry:
        return 'text.orga_type_industry'
      case OrganizationType.OrganizationTypeUniversity:
      case OrganizationType.OrganizationTypeResearch :
      case OrganizationType.OrganizationTypeScienceOther:
        return 'text.orga_type_science'
      default:
        return ''
    }
  }
  return ''
}

export function isActiveFilter (candidate: Filter, activeFilters: Array<Filter>): boolean {
  for (const activeFilter of activeFilters) {
    if (candidate.key === activeFilter.key && candidate.value === activeFilter.value) {
      return true
    }
  }

  return false
}

export function availableFilterByGroup (filters: Array<Filter>, activeFilters: Array<Filter>): Array<FilterGroupData> {
  // filters is a list of available filters which must be sorted
  // in the sense that filters are grouped by group: So first
  // come all the elements for group a then come all the elements
  // for group b and so forth.

  const byGroup: Array<FilterGroupData> = []

  let lastGroup = ''
  for (const filter of filters) {
    if (filter.group !== lastGroup) {
      byGroup.push(
        {
          group: filter.group,
          verbose: i18n.global.t(`filter_key_verbose.${filter.group}`),
          verboseNotActive: i18n.global.t(`filter_key_verbose_not_active.${filter.group}`),
          filters: [],
          activeFilter: null
        })
    }

    byGroup[byGroup.length - 1].filters.push(filter)

    if (isActiveFilter(filter, activeFilters)) {
      byGroup[byGroup.length - 1].activeFilter = filter
    }

    lastGroup = filter.group
  }

  return byGroup
}
