
import { defineComponent, ref } from 'vue'
import { useField } from 'vee-validate'

export default defineComponent({
  props: {
    label: String,
    name: {
      type: String,
      required: true
    },
    accept: String,
    elementStyle: String,
    labelStyle: String,
    helpText: String,
    placeholder: String
  },
  setup (props) {
    const {
      errorMessage,
      handleBlur,
      handleChange,
      meta
    } = useField(props.name, undefined)
    const inputFile = ref<InstanceType<typeof HTMLInputElement>>()

    return {
      handleChange,
      handleBlur,
      errorMessage,
      meta,
      inputFile
    }
  },
  computed: {
    helpId (): string {
      return this.name + 'Help'
    }
  },
  methods: {
    reset () {
      if (this.inputFile) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.inputFile.value = null
      }
    }
  }
})
