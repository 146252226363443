<template>
  <iframe :src="srcUrl"
          width="100%"
          id="iframe-component"></iframe>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Iframe',
  props: {
    srcUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    /**
     * Sets the height of a specific iframe.
     *
     * @param {string} id - The ID of the iframe to be manipulated
     * @param {number} ht - The height to be applied to the iframe
     * @return void
     */
    setIframeHeightCO: function (id, ht) {
      const ifrm = document.getElementById(id)

      // Hide it as long as its height was not manipulated
      ifrm.style.visibility = 'hidden'
      // some IE versions need a bit added or scrollbar appears
      ifrm.style.height = ht + 4 + 'px'
      ifrm.style.visibility = 'visible'
    },
    /**
     * Handles the height for an iframe to be applied
     * that was sent via postMessage.
     *
     * @param {event} e
     * @return void
     */
    handleDocHeightMsg: function (e) {
      // Check origin
      if (e.origin === 'https://www.total-e-quality.de') {
        // parse data
        const data = JSON.parse(e.data)
        // check data object
        if (data.docHeight) {
          this.setIframeHeightCO('iframe-component', data.docHeight)
        }
      }
    }
  },
  mounted: function () {
    // The counterpart which sends the message is implemented here:
    // https://gitlab.descript.de/descript/total_e_quality/-/blob/master/total_e_quality/templates/base_online_application.html
    window.addEventListener('message', this.handleDocHeightMsg, false)
  }
})
</script>

<style scoped>

</style>
