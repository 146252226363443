
import { defineComponent, ref } from 'vue'
import {
  applicationInReadonlyMode,
  errorHelper,
  getApplicationPeriod,
  getAreaOfCompany,
  getCorrespondingFormForFormType,
  getFieldOfActionActionBorders,
  getStateOfApplicationForm,
  getStateOfFoa,
  getStateOfForm
} from '@/utils'
import {
  Application,
  ApplicationForm,
  ApplicationPeriod,
  ApplicationState,
  FieldOfAction,
  FormType,
  Organization
} from '@/types/descript_application_portal_rest'
import ApplicationStateComponent from '@/components/application/ApplicationStateComponent.vue'
import ProgressWheel from '@/components/application/ProgressWheel.vue'
import MainOverviewContainer from '@/components/base/MainOverviewContainer.vue'
import ValidityOfCertificateComponent from '@/components/application/ValidityOfCertificateComponent.vue'
import { AlertType, FormErrorState } from '@/types/internal'
import Modal from '@/components/modal/Modal.vue'
import ModalHeader from '@/components/modal/ModalHeader.vue'
import ModalBody from '@/components/modal/ModalBody.vue'
import ModalFooter from '@/components/modal/ModalFooter.vue'
import ModalTitle from '@/components/modal/ModalTitle.vue'
import Button from '@/components/base/Button.vue'

export default defineComponent({
  name: 'Overview',
  components: {
    ProgressWheel,
    ApplicationStateComponent,
    MainOverviewContainer,
    ValidityOfCertificateComponent,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalTitle,
    Button
  },
  setup () {
    const finishApplicationModal = ref<InstanceType<typeof Modal>>()
    return { finishApplicationModal }
  },
  data: function () {
    return {
      hasCurrentApplication: false,
      isFinishedLoading: false,
      localFormTypes: FormType,
      applicationInReadonlyMode: applicationInReadonlyMode,
      getStateOfForm: getStateOfForm,
      finishApplicationModalId: 'modal-finish-application',
      statisticFormUploadFiles: [],
      getFieldOfActionActionBorders,
      applicationId: this.$route.params.id ? Number(this.$route.params.id) : null
    }
  },
  methods: {
    getAreaOfCompany,
    getStateOfApplicationForm,
    getDate: function (dateString: string): string {
      const date = new Date(dateString)

      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }

      return date.toLocaleDateString(this.activeLocale, options)
    },
    getFormForFormType: function (formType: FormType): ApplicationForm {
      return getCorrespondingFormForFormType(formType, this.applicationForms)
    },
    getFieldsOfActionState: function () {
      const currentFormStates: Array<string> = []
      if (this.organization) {
        for (const foa of this.fieldsOfAction) {
          currentFormStates.push(getStateOfFoa(foa, getFieldOfActionActionBorders(this.organization)))
        }
        if (currentFormStates.includes(FormErrorState.NotSaved)) {
          return FormErrorState.NotSaved
        } else if (currentFormStates.includes(FormErrorState.HasErrors)) {
          return FormErrorState.HasErrors
        }
        return FormErrorState.WithoutErrors
      }
    },
    getCurrentCertificateValidity: function (organization: Organization | null) {
      if (organization) {
        return organization.current_certificate_is_valid
      }
      return false
    },
    getCurrentApplicationPeriod: function (applicationPeriod: ApplicationPeriod | null) {
      return getApplicationPeriod(applicationPeriod)
    },
    finishApplication: async function () {
      if (this.application) {
        const application = await this.$store.dispatch('setApplicationState', { applicationId: this.application.id, state: ApplicationState.ApplicationStateWaitingForFeedback }).catch(errorHelper(this.$store))
        if (application && application.id) {
          this.$store.commit('addImmediateAlert', {
            msg: 'messages.application_finished',
            type: AlertType.Success
          })
          // Close the modal
          if (this.finishApplicationModal) {
            this.finishApplicationModal.hide()
          }
        }
      }
    },
    showFinishApplicationModal () {
      if (this.application) {
        if (!this.application.is_finishable) {
          this.$store.commit('addImmediateAlert',
            { msg: 'messages.application_not_finishable', type: AlertType.Danger })
          return
        }
      }
      if (this.finishApplicationModal) {
        this.finishApplicationModal.show()
      }
    }
  },
  computed: {
    activeLocale: function (): string {
      return this.$i18n.locale
    },
    application (): Application | null {
      return this.$store.state.currentApplication
    },
    organization (): Organization | null {
      return this.$store.state.organization
    },
    applicationForms (): Array<ApplicationForm> {
      return this.$store.state.applicationForms
    },
    generalInformationForm (): ApplicationForm {
      return this.getFormForFormType(FormType.FormTypeGeneralInformation)
    },
    statisticalInformationForm (): ApplicationForm {
      return this.getFormForFormType(FormType.FormTypeStatisticalInformation)
    },
    basicUnderstandingForm (): ApplicationForm {
      return this.getFormForFormType(FormType.FormTypeBasicUnderstanding)
    },
    currentApplicationPeriod (): ApplicationPeriod | null {
      return this.$store.state.currentApplicationPeriod
    },
    periodOfApplication (): ApplicationPeriod | null {
      return this.$store.state.periodOfApplication
    },
    fieldsOfAction (): Array<FieldOfAction> {
      return this.$store.state.fieldsOfAction
    }
  },
  created: async function () {
    // as the current state of the application is important here it should be fetched everytime
    await this.$store.dispatch('getLatestApplication', this.applicationId).catch(errorHelper(this.$store))
    if (this.application) {
      await this.$store.dispatch('getFormsForApplication').catch(errorHelper(this.$store))
      await this.$store.dispatch('getFieldOfActionForApplication').catch(errorHelper(this.$store))
      this.statisticFormUploadFiles = await this.$store.dispatch('getFormFiles', this.getFormForFormType(FormType.FormTypeStatisticalInformation).id).catch(errorHelper(this.$store))
      this.hasCurrentApplication = true
    }
    this.isFinishedLoading = true
  }
})
