
import { defineComponent } from 'vue'
import Footer from '@/components/base/Footer.vue'

export default defineComponent({
  name: 'MainCard',
  components: {
    Footer
  },
  props: {
    cardStyle: {
      type: String
    }
  }
})
