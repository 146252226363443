
import { defineComponent, ref } from 'vue'
import Header from '@/components/base/Header.vue'
import {
  Application,
  ApplicationAction, ApplicationPeriod,
  FieldOfAction,
  FieldOfActionType
} from '@/types/descript_application_portal_rest'
import {
  applicationInReadonlyMode,
  errorHelper,
  getFieldOfActionActionBorders,
  getStateOfFoa,
  getTitleForFieldOfActionType
} from '@/utils'
import { FormRenderComponent } from '@descript/vue-js-json-forms-bootstrap-renderer'
import { Field } from 'vee-validate'
import { AlertType, FormErrorState } from '@/types/internal'
import SubHeader from '@/components/base/SubHeader.vue'
import MainFormContainer from '@/components/forms/MainFormContainer.vue'
import Alerts from '@/components/base/Alerts.vue'
import MainFormBottomBar from '@/components/forms/MainFormBottomBar.vue'
import ProgressWheel from '@/components/application/ProgressWheel.vue'

export default defineComponent({
  name: 'FieldOfActionDetails',
  components: {
    FormRenderComponent,
    Field,
    Header,
    SubHeader,
    MainFormContainer,
    Alerts,
    MainFormBottomBar,
    ProgressWheel
  },
  setup () {
    // refs to child components in a TypeScript compatible way,
    // see https://v3.vuejs.org/guide/typescript-support.html#typing-refs.
    const form = ref<InstanceType<typeof FormRenderComponent>>()

    return { form }
  },
  data: function () {
    return {
      isLoaded: false,
      fieldOfActionId: this.$route.params.id ? Number(this.$route.params.id) : null,
      fieldOfAction: {} as FieldOfAction,
      currentApplicationActions: [] as Array<ApplicationAction>,
      applicationInReadonlyMode: applicationInReadonlyMode,
      getStateOfFoa: getStateOfFoa,
      currentFormState: FormErrorState.NotSaved,
      showErrors: false,
      foaNumber: 0,
      getFieldOfActionActionBorders
    }
  },
  computed: {
    application: function (): Application | null {
      return this.$store.state.currentApplication
    },
    currentApplicationPeriod: function (): ApplicationPeriod | null {
      return this.$store.state.currentApplicationPeriod
    }
  },
  methods: {
    getFieldTitle: function (fieldType: FieldOfActionType): string {
      return getTitleForFieldOfActionType(fieldType)
    },
    handleSubmit: async function (data: JSON, withoutAlert?: boolean) {
      if (this.application) {
        const actionCountConstraints = getFieldOfActionActionBorders(this.application.organization)
        if (this.fieldOfAction.actions.length > actionCountConstraints.max) {
          this.$store.commit('addImmediateAlert', {
            msg: `${this.$t('fields_of_action.can_not_add_more_actions', { max: actionCountConstraints.max })}`,
            type: AlertType.Danger
          })
        } else {
          await this.$store.dispatch('storeFieldOfActionFormAnswers', {
            fieldOfActionId: this.fieldOfAction.id,
            answers: data,
            actions: this.fieldOfAction.actions,
            errors: this.form ? this.form.errors : null
          }).then(() => {
            if (!withoutAlert) {
              this.$store.commit('addImmediateAlert', {
                msg: 'fields_of_action.save_successful',
                type: AlertType.Success
              })
            }
          }, errorHelper(this.$store))
        }
      }
    },
    checkForCount: function (checkedTrigger: any) {
      if (this.application) {
        const actionCountConstraints = getFieldOfActionActionBorders(this.application.organization)
        if (this.fieldOfAction.actions.length > actionCountConstraints.max) {
          const index = this.fieldOfAction.actions.indexOf(Number(checkedTrigger.target.value))
          if (index > -1) {
            this.fieldOfAction.actions.splice(index, 1)
          }
          this.$store.commit('addImmediateAlert', {
            msg: `${this.$t('fields_of_action.can_not_add_more_actions', { max: actionCountConstraints.max })}`,
            type: AlertType.Danger
          })
        } else {
          if (this.form && this.form.data) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.handleSubmit(this.form.data, true)
          }
        }
      }
    },
    formIsReadOnly: function () {
      if (this.form) {
        return this.form.isReadOnly
      }

      return false
    },
    handleFinish: function () {
      if (this.form) {
        this.form.switchToValidateAndShow()
        this.showErrors = true

        if (this.form.errors) {
          if (this.form.errors.length === 0) {
            this.$store.commit('addImmediateAlert', {
              msg: 'form_validation.no_error',
              type: AlertType.Success
            })
          } else {
            this.$store.commit('addImmediateAlert', {
              msg: 'form_validation.with_error',
              type: AlertType.Warning
            })
          }
        }
      }
    },
    updateState (data: JSON) {
      if (this.form && this.form.errors) {
        if (this.form.errors.length > 0) {
          this.currentFormState = FormErrorState.HasErrors
        } else {
          this.currentFormState = FormErrorState.WithoutErrors
        }
      }
      this.handleSubmit(data, true)
    }
  },
  created: async function () {
    // as the current state of the application is important here it should be fetched everytime
    await this.$store.dispatch('getCurrentApplicationPeriod').catch(errorHelper(this.$store))
    this.fieldOfAction = await this.$store.dispatch('getSpecificFieldOfAction', this.fieldOfActionId).catch(errorHelper(this.$store))
    await this.$store.dispatch('getLatestApplication', this.fieldOfAction.application).catch(errorHelper(this.$store))
    const allFOAs: Array<FieldOfAction> = await this.$store.dispatch('getFieldOfActionForApplication').catch(errorHelper(this.$store))
    if (this.fieldOfAction && this.application) {
      this.currentFormState = getStateOfFoa(this.fieldOfAction as FieldOfAction, getFieldOfActionActionBorders(this.application.organization))
      this.currentApplicationActions = await this.$store.dispatch('getCurrentActions').catch(errorHelper(this.$store))
      let count = 1
      for (const foa of allFOAs) {
        if (foa.id === this.fieldOfAction.id) {
          this.foaNumber = count
          break
        }
        count++
      }
      this.isLoaded = true
    }
  },
  mounted: function () {
    // Apply a CSS selector to the body element
    // that can be used later on to apply form-specific styles
    document.body.className = 'app-view-form-4'
  },
  unmounted: function () {
    // We have to reset the body class on leaving the view
    document.body.className = ''
  }
})
