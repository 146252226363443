import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge badge-info rounded-pill ms-1"
}
const _hoisted_2 = {
  key: 1,
  class: "badge badge-info rounded-pill ms-1"
}
const _hoisted_3 = {
  key: 2,
  class: "badge badge-warning rounded-pill ms-1"
}
const _hoisted_4 = {
  key: 3,
  class: "badge badge-error rounded-pill ms-1"
}
const _hoisted_5 = {
  key: 4,
  class: "badge badge-success rounded-pill ms-1"
}
const _hoisted_6 = {
  key: 5,
  class: "badge badge-error rounded-pill ms-1"
}
const _hoisted_7 = {
  key: 6,
  class: "badge badge-warning rounded-pill ms-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.state === _ctx.localApplicationStates.ApplicationStateStarted)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('application.state_verbose.started')), 1))
    : (_ctx.state === _ctx.localApplicationStates.ApplicationStateInProgress)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('application.state_verbose.in_progress')), 1))
      : (_ctx.state === _ctx.localApplicationStates.ApplicationStateWaitingForFeedback)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('application.state_verbose.waiting_for_feedback')), 1))
        : (_ctx.state === _ctx.localApplicationStates.ApplicationStateAdditionalInfoNeeded)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('application.state_verbose.additional_info_needed')), 1))
          : (_ctx.state === _ctx.localApplicationStates.ApplicationStateSuccessful)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('application.state_verbose.successful')), 1))
            : (_ctx.state === _ctx.localApplicationStates.ApplicationStateRejected)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('application.state_verbose.rejected')), 1))
              : (_ctx.state === _ctx.localApplicationStates.ApplicationStateInEvaluation)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('application.state_verbose.in_evaluation')), 1))
                : _createCommentVNode("", true)
}