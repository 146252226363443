
import { defineComponent, ref } from 'vue'
import Header from '@/components/base/Header.vue'
import Alerts from '@/components/base/Alerts.vue'
import MainFormContainer from '@/components/forms/MainFormContainer.vue'
import SVGCheck from '@/components/svg/SVGCheck.vue'
import ProgressWheel from '@/components/application/ProgressWheel.vue'
import {
  Application, ApplicationAction,
  ApplicationForm, ApplicationPeriod,
  FormType,
  getPlaceholderApplication,
  getPlaceholderApplicationForm
} from '@/types/descript_application_portal_rest'
import {
  applicationInReadonlyMode,
  errorHelper,
  getStateOfApplicationForm,
  isStatisticalFormOfScienceOrganization
} from '@/utils'
import { FormRenderComponent } from '@descript/vue-js-json-forms-bootstrap-renderer'
import SubHeader from '@/components/base/SubHeader.vue'
import { AlertType, FormErrorState } from '@/types/internal'
import MainFormBottomBar from '@/components/forms/MainFormBottomBar.vue'
import ApplicationFormFileUpload from '@/components/application_form/ApplicationFormFileUpload.vue'
import { Tooltip } from 'bootstrap'

export default defineComponent({
  name: 'FormView',
  components: {
    Alerts,
    ApplicationFormFileUpload,
    FormRenderComponent,
    Header,
    MainFormContainer,
    SVGCheck,
    ProgressWheel,
    SubHeader,
    MainFormBottomBar
  },
  computed: {
    currentApplicationPeriod: function (): ApplicationPeriod | null {
      return this.$store.state.currentApplicationPeriod
    }
  },
  setup () {
    // refs to child components in a TypeScript compatible way,
    // see https://v3.vuejs.org/guide/typescript-support.html#typing-refs.
    const form = ref<InstanceType<typeof FormRenderComponent>>()
    const applicationFormFileUpload = ref<InstanceType<typeof ApplicationFormFileUpload>>()

    return { form, applicationFormFileUpload }
  },
  data: function () {
    return {
      formId: this.$route.params.id ? Number(this.$route.params.id) : null,
      applicationForm: getPlaceholderApplicationForm(),
      application: getPlaceholderApplication(),
      applicationInReadonlyMode: applicationInReadonlyMode,
      currentFormState: FormErrorState.NotSaved,
      showErrors: false,
      isLoaded: false,
      applicationFormFiles: [],
      isAdmin: !!localStorage.getItem('isAdmin'),
      FormType,
      tooltipTriggerList: []
    }
  },
  methods: {
    isStatisticalFormOfScienceOrganization,
    getStateOfApplicationForm,
    getFormTitle (formType: FormType) {
      switch (formType) {
        case FormType.FormTypeBasicUnderstanding:
          return 'action_fields.our_base_values'
        case FormType.FormTypeGeneralInformation:
          return 'action_fields.basic_information'
        case FormType.FormTypeStatisticalInformation:
          return 'action_fields.statistical_information'
        default:
          return ''
      }
    },
    updateState (data: JSON) {
      if (this.form && this.form.errors) {
        if (this.isStatisticalFormOfScienceOrganization(this.applicationForm as ApplicationForm, this.application.organization) && this.applicationFormFiles.length === 0) {
          this.currentFormState = FormErrorState.HasErrors
        } else if (this.form.errors.length > 0) {
          this.currentFormState = FormErrorState.HasErrors
        } else {
          this.currentFormState = FormErrorState.WithoutErrors
        }
      }
      this.handleSubmit(data, true)
    },
    handleSubmit: function (data: JSON, withoutAlert?: boolean) {
      this.$store.dispatch('storeApplicationFormAnswers', {
        applicationFormId: this.applicationForm.id,
        answers: data,
        errors: this.form ? this.form.errors : null
      }).then(() => {
        if (!withoutAlert) {
          this.$store.commit('addImmediateAlert',
            {
              msg: 'application.save_successful',
              type: AlertType.Success
            })
        }
      }, errorHelper(this.$store))
    },
    formIsReadOnly: function () {
      if (this.form) {
        return this.form.isReadOnly
      }

      return false
    },
    handleFinish: function () {
      if (this.form) {
        this.form.switchToValidateAndShow()
        this.showErrors = true
        if (this.isStatisticalFormOfScienceOrganization(this.applicationForm as ApplicationForm, this.application.organization) && this.applicationFormFiles.length === 0) {
          this.$store.commit('addImmediateAlert', {
            msg: 'form_validation.with_missing_xlsx',
            type: AlertType.Danger
          })
        } else if (this.form.errors) {
          if (this.form.errors.length === 0) {
            this.$store.commit('addImmediateAlert', {
              msg: 'form_validation.no_error',
              type: AlertType.Success
            })
          } else {
            this.$store.commit('addImmediateAlert', {
              msg: 'form_validation.with_error',
              type: AlertType.Danger
            })
          }
        }
      }
    },
    reloadFiles: async function () {
      const applicationFormFiles = await this.$store.dispatch('getFormFiles', this.applicationForm.id).catch(errorHelper(this.$store))
      if (applicationFormFiles) {
        this.disposeTooltips()
        this.applicationFormFiles = applicationFormFiles
        this.$nextTick().then(() => this.createTooltips())
      }
    },
    deleteApplicationFormFile: async function (id: number) {
      if (this.application && this.currentApplicationPeriod && !applicationInReadonlyMode(this.application, this.currentApplicationPeriod)) {
        await this.$store.dispatch('deleteApplicationFormFile', id).catch(errorHelper(this.$store))
        await this.reloadFiles()
      } else {
        this.$store.commit('addImmediateAlert', { msg: 'messages.can_not_delete', type: AlertType.Warning })
      }
    },
    showApplicationFormFileUploadForm: function () {
      if (this.applicationFormFileUpload) {
        this.applicationFormFileUpload.show()
      }
    },
    disposeTooltips: function () {
      this.tooltipTriggerList.map(function (tooltipTriggerEl) {
        const tooltipEl = Tooltip.getInstance(tooltipTriggerEl)
        if (tooltipEl !== null) {
          tooltipEl.dispose()
        }
      })
    },
    createTooltips: function () {
      this.disposeTooltips()
      this.tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      this.tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
      })
    }
  },
  created: async function () {
    // as the current state of the application is important here it should be fetched everytime
    await this.$store.dispatch('getCurrentApplicationPeriod').catch(errorHelper(this.$store))
    this.applicationForm = await this.$store.dispatch('getApplicationForm', this.formId).catch(errorHelper(this.$store))
    this.application = await this.$store.dispatch('getLatestApplication', this.applicationForm.application).catch(errorHelper(this.$store))
    if (this.applicationForm) {
      this.isLoaded = true
      if (this.applicationForm.form_type === FormType.FormTypeBasicUnderstanding || this.applicationForm.form_type === FormType.FormTypeStatisticalInformation) {
        this.applicationFormFiles = await this.$store.dispatch('getFormFiles', this.applicationForm.id).catch(errorHelper(this.$store))
        this.$nextTick().then(() => this.createTooltips())
      }
      // Apply a CSS selector to the body element
      // that can be used later on to apply form-specific styles
      document.body.className = 'app-view-form-' + this.applicationForm.form_type
    }
    this.currentFormState = getStateOfApplicationForm(this.applicationForm as ApplicationForm, this.application.organization, this.applicationFormFiles.length)
  },
  unmounted: function () {
    // We have to reset the body class on leaving the view
    document.body.className = ''

    this.disposeTooltips()
  }
})
