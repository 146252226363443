import axios from 'axios'
import { axiosConfiguration } from '@/config/config.ts'
import { version } from '../package.json'

const axiosInstance = axios.create(axiosConfiguration)

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('authToken')
  const language = localStorage.getItem('currentSelectedLanguage')
  if (token !== null) {
    config.headers.Authorization = 'Token ' + token
  }
  if (language !== null) {
    config.headers.common['Accept-Language'] = language
  }
  return config
})

axiosInstance.interceptors.response.use(function checkResponse (successResponse) {
  if (successResponse.headers &&
    successResponse.headers['frontend-server-version'] &&
    successResponse.headers['frontend-server-version'] !== version) {
    window.dispatchEvent(
      new CustomEvent('vueVersionUpdated')
    )
  }
  return successResponse
}, undefined)

export default axiosInstance
