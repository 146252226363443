import { Filter, InstancesWithSearchAndFilterState, Message } from '@/types/internal'
import {
  Application,
  Organization,
  ApplicationForm,
  ApplicationPeriod,
  ApplicationAction, FieldOfAction
} from '@/types/descript_application_portal_rest'

/**
 * Current State of the Vue App
 */
export interface State {
  immediateAlerts: Array<Message>,
  pendingAlerts: Array<Message>
  currentApplication: Application | null,
  organization: Organization | null
  applicationForms: Array<ApplicationForm>,
  currentApplicationPeriod: ApplicationPeriod | null,
  periodOfApplication: ApplicationPeriod | null,
  applicationActions: Array<ApplicationAction>,
  fieldsOfAction: Array<FieldOfAction>,
  allApplications: Array<Application>
  applicationPeriods: Record<number, ApplicationPeriod>,
  allOrganizations: Record<string, InstancesWithSearchAndFilterState<Organization>>,
  availableOrganizationFilters: Array<Filter>
}

export function getEmptyState (): State {
  return {
    immediateAlerts: [],
    pendingAlerts: [],
    currentApplication: null,
    organization: null,
    applicationForms: [],
    currentApplicationPeriod: null,
    periodOfApplication: null,
    applicationActions: [],
    fieldsOfAction: [],
    allApplications: [],
    applicationPeriods: {},
    allOrganizations: {},
    availableOrganizationFilters: []
  }
}
