import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "h2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoTitle = _resolveComponent("LogoTitle")!
  const _component_Alerts = _resolveComponent("Alerts")!
  const _component_MainCard = _resolveComponent("MainCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LogoTitle, {
      title: _ctx.$t('meta.pageTitles.login')
    }, null, 8, ["title"]),
    _createVNode(_component_MainCard, null, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('auth.account_activation')), 1),
        _createVNode(_component_Alerts, { sticky: "sticky-top" }),
        (!_ctx.activationError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('auth.getting_activated')), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('auth.activation_mishap')), 1)
            ]))
      ]),
      _: 1
    })
  ], 64))
}