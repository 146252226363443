
import { defineComponent } from 'vue'
import http from '@/http-common'
import { errorHelper, handleLogout } from '@/utils'
import { AlertType } from '@/types/internal'

export default defineComponent({
  name: 'Logout',
  props: {
    logoutStyle: {
      type: String,
      default: 'nav-link'
    }
  },
  methods: {
    signOut: function () {
      const token = localStorage.getItem('authToken')
      if (token !== null) {
        http.post('auth/token/logout/').then(
          () => {
            handleLogout(this.$store)
            this.$router.push('/auth')
          }, errorHelper(this.$store, (error) => {
            this.$store.commit('addImmediateAlert', { msg: error.toString(), type: AlertType.Danger })
          })
        ).catch(
          (response) => {
            this.$store.commit('addImmediateAlert', { msg: response.message, type: AlertType.Danger })
          }
        )
      }
    }
  }
})
