/* eslint camelcase: "off" */

export enum OrganizationType {
  OrganizationTypeUniversity = 1,
  OrganizationTypeAdministration = 2,
  OrganizationTypeIndustry = 3,
  OrganizationTypeAssociation = 4,
  OrganizationTypeResearch = 5,
  OrganizationTypeScienceOther = 6
}

export enum ApplicationState {
  ApplicationStateStarted = 1,
  ApplicationStateInProgress = 2,
  ApplicationStateWaitingForFeedback = 3,
  ApplicationStateAdditionalInfoNeeded = 4,
  ApplicationStateSuccessful = 5,
  ApplicationStateRejected = 6,
  ApplicationStateInEvaluation = 7
}

export enum FieldOfActionType {
  Structural = 1,
  Organization = 2,
  Personal = 3,
  WorkLife = 4,
  WorkStudyLife = 5,
  ProperBehaviour = 6,
  EqualOpportunityEducation = 7
}

export enum FormType {
  FormTypeGeneralInformation = 1,
  FormTypeStatisticalInformation = 2,
  FormTypeBasicUnderstanding = 3
}

export enum OrganizationSize {
  OrganizationSizeSmall = 1,
  OrganizationSizeMiddle = 2,
  OrganizationSizeLarge = 3
}

export enum OrganizationCertificateValidity {
  CertificateIsValid = 1,
  CertificateIsInGracePeriod = 2,
  CertificateIsNotValid = 3
}

export interface ApplicationPeriod {
  id: number,
  valid_from: string,
  valid_to: string
}

export interface Organization {
  id: number,
  name: string,
  type: OrganizationType,
  current_certificate_is_valid: OrganizationCertificateValidity,
  organization_size: OrganizationSize,
  logo: string,
  latest_application_id: number,
  latest_application_application_period: ApplicationPeriod | null
}

export function getPlaceholderOrganization (): Organization {
  return {
    id: 0,
    name: '',
    type: OrganizationType.OrganizationTypeAdministration,
    current_certificate_is_valid: OrganizationCertificateValidity.CertificateIsNotValid,
    organization_size: OrganizationSize.OrganizationSizeSmall,
    logo: '',
    latest_application_id: 0,
    latest_application_application_period: null
  }
}

export interface Application {
  id: number,
  state: ApplicationState,
  organization: Organization,
  deadline: string,
  last_modified: string | null,
  application_period: number,
  is_finishable: boolean
}

export function getPlaceholderApplication (): Application {
  const organization = getPlaceholderOrganization()

  return {
    id: 0,
    state: ApplicationState.ApplicationStateStarted,
    organization: organization,
    deadline: '',
    last_modified: null,
    application_period: 0,
    is_finishable: false
  }
}

export interface ApplicationForm {
  id: number,
  application: number,
  title: string,
  description: string,
  form_schema: JSON,
  form_ui_schema: JSON,
  answers: JSON,
  errors: Array<JSON> | null,
  organization_type: OrganizationType,
  form_type: FormType
}

export function getPlaceholderApplicationForm (): ApplicationForm {
  return {
    id: 0,
    application: 0,
    title: '',
    description: '',
    form_schema: JSON.parse('{}'),
    form_ui_schema: JSON.parse('{}'),
    answers: JSON.parse('{}'),
    errors: null,
    organization_type: OrganizationType.OrganizationTypeScienceOther,
    form_type: FormType.FormTypeGeneralInformation
  }
}

export interface ApplicationAction {
  id: number,
  title: string,
  short_description: string,
  goals_and_milestones: string,
  anchor_points_and_responsible_persons: string,
  involved_persons: string,
  timings_and_procedure: string,
  costs_and_financing: string,
  evaluation_and_monitoring: string,
  legal_requirement: boolean | null,
  application: number,
  field_of_action: Array<number>,
  link_to_describe: string
}

export interface FieldOfAction {
  id: number,
  application: number,
  form_schema: JSON,
  form_ui_schema: JSON,
  answers: JSON,
  errors: Array<JSON> | null,
  actions: Array<number>,
  field_of_action_type: FieldOfActionType
}

export interface ActionFile {
  id: number,
  action: number,
  name: string
  uploaded_file: string
}

export interface ApplicationFormFile {
  id: number,
  application_form: number,
  name: string,
  uploaded_file: string
}
