
import { defineComponent } from 'vue'
import { ApplicationState } from '@/types/descript_application_portal_rest'

export default defineComponent({
  name: 'ApplicationStateComponent',
  props: {
    state: {
      required: true
    }
  },
  data: function () {
    return {
      localApplicationStates: ApplicationState
    }
  }
})
