import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row row-cols-md-2 row-cols-lg-4" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!
  const _component_MainOverviewContainer = _resolveComponent("MainOverviewContainer")!

  return (_openBlock(), _createBlock(_component_MainOverviewContainer, null, {
    title: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('meta.pageTitles.conditions_of_participation')), 1)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Card, {
            title: _ctx.$t('text.about_teq_title'),
            text: _ctx.$t('text.about_teq_subtitle'),
            link: "https://www.total-e-quality.de/",
            "is-external-url": "true"
          }, null, 8, ["title", "text"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Card, {
            title: _ctx.$t('text.participation_title'),
            text: _ctx.$t('text.participation_subtitle'),
            link: "/conditions_of_participation/conditions"
          }, null, 8, ["title", "text"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Card, {
            title: _ctx.$t('text.application_guide_title'),
            text: _ctx.$t('text.application_guide_subtitle'),
            link: "/conditions_of_participation/instruction"
          }, null, 8, ["title", "text"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Card, {
            title: _ctx.$t('text.become_member_title'),
            text: _ctx.$t('text.become_member_subtitle'),
            link: "/conditions_of_participation/membership"
          }, null, 8, ["title", "text"])
        ])
      ])
    ]),
    _: 1
  }))
}