
import { defineComponent } from 'vue'
import { Form } from 'vee-validate'
import Alerts from '@/components/base/Alerts.vue'
import FormInput from '@/components/forms/FormInput.vue'
import * as yup from 'yup'
import { AlertType } from '@/types/internal'
import { errorHelper } from '@/utils'
import http from '@/http-common.ts'
import MainCard from '@/components/base/MainCard.vue'
import LogoTitle from '@/components/base/LogoTitle.vue'

export default defineComponent({
  name: 'RequestPasswordReset',
  components: {
    MainCard,
    FormInput,
    Alerts,
    Form,
    LogoTitle
  },
  data: function () {
    const schema = yup.object().shape({
      emailInput: yup.string()
        .required(this.$t('form_validation.required'))
        .email(this.$t('form_validation.email'))
    })

    return {
      schema: schema,
      emailInput: ''
    }
  },
  methods: {
    getResetLink (values: Record<string, string>) {
      http.post('auth/users/reset_password/', { email: values.emailInput }).then(
        () => {
          this.$store.commit('clearAndDismissImmediateAlerts')
          this.$store.commit(
            'addImmediateAlert',
            {
              msg: 'auth.reset_link_success_msg',
              type: AlertType.Success
            })
        }, errorHelper(this.$store, (error) => {
          if ('data' in error.response) {
            this.$store.commit('addImmediateAlert', {
              msg: error.response.data.toString(),
              type: AlertType.Danger
            })
          } else {
            this.$store.commit('addImmediateAlert', {
              msg: error.toString(),
              type: AlertType.Danger
            })
          }
        })).catch(
        (response) => {
          this.$store.commit('addImmediateAlert', {
            msg: response.message,
            type: AlertType.Danger
          })
        }
      )
    }
  }
})
