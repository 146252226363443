
import { defineComponent, PropType } from 'vue'
import { useField } from 'vee-validate'

export interface Option {
  id: number,
  label: string
}

export default defineComponent({
  props: {
    label: String,
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array as PropType<Array<Option>>,
      required: true
    },
    value: Array as PropType<Array<number>>,
    emptyValue: {
      required: false,
      default: null
    },
    labelStyle: String,
    isMultiple: {
      required: false,
      default: false
    }
  },
  setup (props) {
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      meta
    } = useField(props.name, undefined, {
      initialValue: props.value
    })

    return {
      inputValue,
      errorMessage,
      handleChange,
      meta
    }
  }
})
