<template>
  <header class="container d-flex justify-content-center align-items-center py-6">
    <img alt="Logo: TOTAL E-QUALITY"
         src="@/assets/images/TEQ_logo.svg"
         class="img-logo-title">
    <h1 class="my-0 ms-4 pt-0">
      {{ title }}
    </h1>
  </header>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LogoTitle',
  props: {
    title: {
      type: String
    }
  }
})
</script>

<style scoped>
</style>
