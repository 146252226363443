<template>
  <span class="badge rounded-circle p-0 size-square-3"
        :class="[atStart ? 'me-1' : 'ms-1']">
    <span class="visually-hidden">
      {{ label }}
    </span>
  </span>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BadgeRound',
  props: {
    label: {
      type: String,
      required: true
    },
    atStart: {
      type: Boolean,
      default: true
    }
  }
})
</script>

<style scoped>

</style>
