import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-grid" }
const _hoisted_2 = {
  class: "btn btn-secondary",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoTitle = _resolveComponent("LogoTitle")!
  const _component_Alerts = _resolveComponent("Alerts")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_Form = _resolveComponent("Form")!
  const _component_MainCard = _resolveComponent("MainCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LogoTitle, {
      title: _ctx.$t('meta.pageTitles.login')
    }, null, 8, ["title"]),
    _createVNode(_component_MainCard, null, {
      default: _withCtx(() => [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('auth.new_password_title')), 1),
        _createVNode(_component_Alerts, { sticky: "sticky-top" }),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('auth.new_password_text')), 1),
        _createVNode(_component_Form, {
          onSubmit: _ctx.saveNewPassword,
          "validation-schema": _ctx.schema
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormInput, {
              type: "password",
              label: _ctx.$t('auth.new_password_title'),
              name: "new_password"
            }, null, 8, ["label"]),
            _createVNode(_component_FormInput, {
              type: "password",
              label: _ctx.$t('auth.new_password_repeat'),
              name: "re_new_password"
            }, null, 8, ["label"]),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("button", _hoisted_2, _toDisplayString(_ctx.$t('auth.new_password_submit')), 1)
            ])
          ]),
          _: 1
        }, 8, ["onSubmit", "validation-schema"])
      ]),
      _: 1
    })
  ], 64))
}