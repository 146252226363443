
import { defineComponent } from 'vue'
import { Tooltip } from 'bootstrap'
import { FormErrorState } from '@/types/internal'

export default defineComponent({
  name: 'ProgressWheel',
  props: {
    state: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      tooltipTriggerList: [],
      localFormErrorState: FormErrorState
    }
  },
  computed: {
    activeTooltipNotSaved: function () : boolean {
      return (this.state === this.localFormErrorState.NotSaved)
    },
    activeTooltipHasErrors: function () : boolean {
      return (this.state === this.localFormErrorState.HasErrors)
    },
    activeTooltipWithoutErrors: function (): boolean {
      return (this.state === this.localFormErrorState.WithoutErrors)
    }
  },
  methods: {
    getProgressStateVerbose: function (progressState: string): string {
      return this.$t(`progressStates.${progressState}`)
    },
    disposeTooltips: function () {
      this.tooltipTriggerList.map(function (tooltipTriggerEl) {
        const tooltipEl = Tooltip.getInstance(tooltipTriggerEl)
        if (tooltipEl !== null) {
          tooltipEl.dispose()
        }
      })
    },
    createTooltips: function () {
      this.disposeTooltips()
      this.tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      this.tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
      })
    }
  },
  mounted: function () {
    this.createTooltips()
  },
  unmounted: function () {
    this.disposeTooltips()
  }
})
