import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    class: "form-select",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChange($event))),
    "aria-label": _ctx.$t('meta.locale.chooser_select_label')
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.LOCALES, (locale, index) => {
      return (_openBlock(), _createElementBlock("option", {
        key: index,
        value: locale.value,
        selected: locale.value === _ctx.activeLocale
      }, _toDisplayString(locale.caption), 9, _hoisted_2))
    }), 128))
  ], 40, _hoisted_1)), [
    [_vModelSelect, _ctx.$i18n.locale]
  ])
}