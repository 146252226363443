
import { defineComponent } from 'vue'
import Header from '@/components/base/Header.vue'
import Footer from '@/components/base/Footer.vue'
import Alerts from '@/components/base/Alerts.vue'
import { errorHelper, getFieldOfActionActionBorders, getStateOfFoa, getTitleForFieldOfActionType } from '@/utils'
import { Application, FieldOfAction, FieldOfActionType } from '@/types/descript_application_portal_rest'
import ProgressWheel from '@/components/application/ProgressWheel.vue'
import SubHeader from '@/components/base/SubHeader.vue'

export default defineComponent({
  name: 'FieldOfActionOverview',
  components: {
    SubHeader,
    ProgressWheel,
    Alerts,
    Footer,
    Header
  },
  data: function () {
    return {
      applicationAndFieldsAreLoaded: false,
      applicationId: this.$route.params.id ? Number(this.$route.params.id) : null
    }
  },
  computed: {
    fieldsOfActions: function (): Array<FieldOfAction> {
      return this.$store.state.fieldsOfAction
    },
    application: function (): Application | null {
      return this.$store.state.currentApplication
    },
    constraints: function (): Record<string, number> {
      if (this.application) {
        return getFieldOfActionActionBorders(this.application.organization)
      }
      return {}
    }
  },
  methods: {
    getFieldTitle: function (fieldType: FieldOfActionType): string {
      return getTitleForFieldOfActionType(fieldType)
    },
    getStateOfField: function (field: FieldOfAction): string {
      return getStateOfFoa(field, this.constraints)
    },
    showErrorMessage: function (field: FieldOfAction): boolean {
      return field.actions.length > this.constraints.max || field.actions.length < this.constraints.min
    }
  },
  created: async function () {
    await this.$store.dispatch('getLatestApplication', this.applicationId).catch(errorHelper(this.$store))
    await this.$store.dispatch('getFieldOfActionForApplication', this.applicationId).catch(errorHelper(this.$store))
    if (this.fieldsOfActions.length !== 0) {
      this.applicationAndFieldsAreLoaded = true
    }
  },
  mounted: function () {
    // Apply a CSS selector to the body element
    // that can be used later on to apply form-specific styles
    document.body.className = 'app-view-form-4'
  },
  unmounted: function () {
    // We have to reset the body class on leaving the view
    document.body.className = ''
  }
})
