
import { defineComponent } from 'vue'
import {
  applicationInReadonlyMode,
  errorHelper,
  getApplicationActionBorders,
  getFieldOfActionActionBorders
} from '@/utils'
import { Application, ApplicationAction, ApplicationPeriod } from '@/types/descript_application_portal_rest'
import SearchInput from '@/components/base/SearchInput.vue'
import { AlertType } from '@/types/internal'
import { Popover } from 'bootstrap'
import SubHeader from '@/components/base/SubHeader.vue'
import Header from '@/components/base/Header.vue'
import Footer from '@/components/base/Footer.vue'

export default defineComponent({
  name: 'ActionsOverview',
  components: {
    SearchInput,
    SubHeader,
    Header,
    Footer
  },
  data: function () {
    const minMaxActions: Record<string, number> = { min: 0, max: 0 }
    return {
      hasCurrentApplication: false,
      applicationInReadonlyMode: applicationInReadonlyMode,
      minMaxActions: minMaxActions,
      fieldsOfActions: {} as Record<number, number>,
      getFieldOfActionActionBorders,
      applicationId: this.$route.params.id ? Number(this.$route.params.id) : null
    }
  },
  computed: {
    currentApplication: function (): Application | null {
      return this.$store.state.currentApplication
    },
    currentActions: function (): Array<ApplicationAction> {
      return this.$store.state.applicationActions
    },
    activeLocale: function (): string {
      return this.$i18n.locale
    },
    currentApplicationPeriod: function (): ApplicationPeriod | null {
      return this.$store.state.currentApplicationPeriod
    }
  },
  created: async function () {
    // as the current state of the application is important here it should be fetched everytime
    await this.$store.dispatch('getCurrentApplicationPeriod').catch(errorHelper(this.$store))
    await this.$store.dispatch('getLatestApplication', this.applicationId).catch(errorHelper(this.$store))
    await this.$store.dispatch('getCurrentActions').catch(errorHelper(this.$store))
    const foas = await this.$store.dispatch('getFieldOfActionForApplication').catch(errorHelper(this.$store))
    let foaCount = 1
    for (const foa of foas) {
      this.fieldsOfActions[foa.id] = foaCount
      foaCount++
    }
    if (this.currentApplication && this.currentApplication.id) {
      this.minMaxActions = getApplicationActionBorders(this.currentApplication.organization)
      this.hasCurrentApplication = true
    }
  },
  methods: {
    getActionTitle: function (action: ApplicationAction): string {
      if (action.title) {
        return action.title
      }
      return this.$t('actions.no_title')
    },
    deleteAction: async function (action: ApplicationAction) {
      if (this.currentApplication && this.currentApplicationPeriod && !applicationInReadonlyMode(this.currentApplication, this.currentApplicationPeriod)) {
        this.$store.dispatch('deleteApplicationAction', action).catch(errorHelper(this.$store))
      } else {
        this.$store.commit('addImmediateAlert', {
          msg: 'messages.can_not_delete',
          type: AlertType.Warning
        })
      }
    },
    doSearch: async function (searchTerm: string) {
      if (this.currentApplication) {
        this.$store.dispatch('getCurrentActions', {
          searchTerm: searchTerm
        }).catch(errorHelper(this.$store))
      }
    },
    resetSearch: async function () {
      if (this.currentApplication) {
        this.$store.dispatch('getCurrentActions').catch(errorHelper(this.$store))
      }
    },
    currentActionsExceedsMax: function () {
      if (this.currentActions && this.currentActions.length >= this.minMaxActions.max) {
        this.$nextTick().then(() => this.initializePopover())
        return true
      } else {
        return false
      }
    },
    initializePopover: function () {
      const popoverElementList = [].slice.call(document.querySelectorAll('.action-overview-popover'))
      popoverElementList.map(function (popoverEl) {
        if (!Popover.getInstance(popoverEl)) {
          return new Popover(popoverEl, {
            container: 'body',
            trigger: 'hover',
            placement: 'right'
          })
        }
      })
    }
  }
})
