
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalHeader',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    className: {
      type: String,
      default: 'modal-header d-flex align-items-center'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitClosed () {
      this.$emit('modalClosed')
    }
  },
  emits: ['modalClosed']
})
