import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed-bottom bg-white border-top border-black-10 py-3 d-print-none" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row justify-content-center" }
const _hoisted_4 = { class: "col-lg-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ])
  ]))
}