import { ActionContext, createStore } from 'vuex'
import { getEmptyState, State } from '@/store/state'
import { mutations } from '@/store/mutations'
import { actions } from '@/store/actions'

export const store = createStore({
  state: getEmptyState(),
  mutations: mutations,
  actions: actions,
  modules: {
  }
})
