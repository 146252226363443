import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "d-grid" }
const _hoisted_9 = {
  class: "btn btn-secondary",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('user_settings.change_password')), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('user_settings.change_password_title')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('user_settings.change_password_subtitle')), 1),
                    _createVNode(_component_Form, {
                      onSubmit: _ctx.changePassword,
                      "validation-schema": _ctx.schema
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_FormInput, {
                          type: "password",
                          label: _ctx.$t('user_settings.current_password'),
                          name: "current_password"
                        }, null, 8, ["label"]),
                        _createVNode(_component_FormInput, {
                          type: "password",
                          label: _ctx.$t('user_settings.change_password_title'),
                          name: "new_password"
                        }, null, 8, ["label"]),
                        _createVNode(_component_FormInput, {
                          type: "password",
                          label: _ctx.$t('user_settings.new_password_repeat'),
                          name: "re_new_password"
                        }, null, 8, ["label"]),
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("button", _hoisted_9, _toDisplayString(_ctx.$t('user_settings.new_password_submit')), 1)
                        ])
                      ]),
                      _: 1
                    }, 8, ["onSubmit", "validation-schema"])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Footer)
  ], 64))
}