
import { defineComponent } from 'vue'
import MainFormContainer from '@/components/forms/MainFormContainer.vue'
import Alerts from '@/components/base/Alerts.vue'
import { Field, Form, FormActions } from 'vee-validate'
import FormInput from '@/components/forms/FormInput.vue'
import { OrganizationSize, OrganizationType } from '@/types/descript_application_portal_rest'
import * as yup from 'yup'
import FormSelect from '@/components/forms/FormSelect.vue'
import FormCheckbox from '@/components/forms/FormCheckbox.vue'
import http from '@/http-common'
import { errorHelper } from '@/utils'
import { AlertType } from '@/types/internal'

interface RegistrationData {
  // organization
  name: string,
  // eslint-disable-next-line camelcase
  organization_type: OrganizationType,
  // eslint-disable-next-line camelcase
  organization_size: OrganizationSize,
  // eslint-disable-next-line camelcase
  current_certificate_is_valid: boolean,
  // eslint-disable-next-line camelcase
  agreed_to_terms_of_service: boolean,
  // user
  username: string,
  email: string,
  password: string,
  // eslint-disable-next-line camelcase
  re_password: string,
  // eslint-disable-next-line camelcase
  accepting_terms: string
}

export default defineComponent({
  name: 'Registration',
  components: {
    MainFormContainer,
    Alerts,
    Form,
    FormInput,
    Field,
    FormSelect,
    FormCheckbox
  },
  data: function () {
    const registrationValidationSchema = yup.object().shape({
      name: yup.string().required(this.$t('form_validation.required')),
      organization_type: yup.number().required(this.$t('form_validation.required_select')).min(1, this.$t('form_validation.required_select')),
      organization_size: yup.number().required(this.$t('form_validation.required_select')).min(1, this.$t('form_validation.required_select')),
      username: yup.string().required(this.$t('form_validation.required')),
      email: yup.string().required(this.$t('form_validation.required')).email(this.$t('form_validation.email')),
      password: yup.string().required(this.$t('form_validation.required')),
      re_password: yup.string().required(this.$t('form_validation.required')).oneOf([yup.ref('password')], this.$t('auth.new_password_miss_match')),
      accepting_terms: yup.string().required(this.$t('form_validation.required')).oneOf(['on'], this.$t('form_validation.terms_required'))
    })
    const organizationTypeOptions = [
      { id: OrganizationType.OrganizationTypeIndustry, label: this.$t('text.orga_type_industry') },
      { id: OrganizationType.OrganizationTypeAdministration, label: this.$t('text.orga_type_admin') },
      { id: OrganizationType.OrganizationTypeAssociation, label: this.$t('text.orga_type_association') },
      { id: OrganizationType.OrganizationTypeUniversity, label: this.$t('text.orga_type_university') },
      { id: OrganizationType.OrganizationTypeResearch, label: this.$t('text.orga_type_research') },
      { id: OrganizationType.OrganizationTypeScienceOther, label: this.$t('text.orga_type_science_other') }
    ]
    const organizationSizeOptions = [
      { id: OrganizationSize.OrganizationSizeSmall, label: this.$t('registration.organization_size_small') },
      { id: OrganizationSize.OrganizationSizeMiddle, label: this.$t('registration.organization_size_middle') },
      { id: OrganizationSize.OrganizationSizeLarge, label: this.$t('registration.organization_size_large') }
    ]
    return {
      instance: {
        // organization
        name: '',
        organization_type: 0,
        organization_size: 0,
        current_certificate_is_valid: false,
        agreed_to_terms_of_service: false,
        // user
        username: '',
        email: '',
        password: '',
        re_password: '',
        accepting_terms: ''
      } as RegistrationData,
      organizationTypeOptions: organizationTypeOptions,
      organizationSizeOptions: organizationSizeOptions,
      registrationValidationSchema: registrationValidationSchema
    }
  },
  methods: {
    finishRegistration: async function (values: RegistrationData, actions: FormActions<Record<string, unknown>>) {
      const userData = await http.post('/auth/users/', JSON.stringify(values, null, 2)).catch(
        errorHelper(this.$store, (error) => {
          actions.setErrors(error.response.data)
        }))
      if (userData && userData.data && userData.data.id) {
        this.$store.commit('addPendingAlert', {
          msg: 'registration.registration_sent',
          type: AlertType.Success
        })
        await this.$router.push('/auth')
      }
    },
    acceptingTermsLabel: function () {
      const routeData = this.$router.resolve('/conditions_of_participation/conditions')
      return this.$t('registration.terms_start', { linkHref: routeData.href })
    }
  }
})
