
import { defineComponent } from 'vue'
import { Dropdown } from 'bootstrap'

export default defineComponent({
  name: 'Dropdown',
  inheritAttrs: false,
  props: {
    id: String,
    label: {
      type: String,
      required: false
    },
    containerStyle: {
      type: String,
      required: false
    },
    asButton: {
      type: Boolean,
      default: false
    }
  },
  mounted: function () {
    const dropdownElementList = [].slice.call(document.querySelectorAll('#' + this.id)) as Array<HTMLElement>
    dropdownElementList.map(function (dropdownToggleEl) {
      if (!Dropdown.getInstance(dropdownToggleEl)) {
        return new Dropdown(dropdownToggleEl)
      }
    })
  }
})
