
/* eslint-disable camelcase */
import { defineComponent, ref } from 'vue'
import {
  ActionFile,
  Application,
  ApplicationAction, ApplicationPeriod,
  FieldOfAction,
  FieldOfActionType
} from '@/types/descript_application_portal_rest'
import Header from '@/components/base/Header.vue'
import { Collapse } from 'bootstrap'
import FormInput from '@/components/forms/FormInput.vue'
import FormTextarea from '@/components/forms/FormTextarea.vue'
import { Field, Form, FormActions } from 'vee-validate'
import {
  applicationInReadonlyMode,
  errorHelper,
  getFieldOfActionActionBorders,
  getTitleForFieldOfActionType
} from '@/utils'
import MainFormContainer from '@/components/forms/MainFormContainer.vue'
import ActionFileUpload from '@/components/application_action/ActionFileUpload.vue'
import MainFormBottomBar from '@/components/forms/MainFormBottomBar.vue'
import { AlertType } from '@/types/internal'
import Alerts from '@/components/base/Alerts.vue'
import ReadOnlyAction from '@/components/application_action/ReadOnlyAction.vue'
import SubHeader from '@/components/base/SubHeader.vue'
import * as yup from 'yup'

interface IncompleteApplicationAction {
  title: string,
  short_description: string,
  goals_and_milestones: string,
  anchor_points_and_responsible_persons: string,
  involved_persons: string,
  timings_and_procedure: string,
  costs_and_financing: string,
  evaluation_and_monitoring: string,
  legal_requirement: boolean | null,
  field_of_action: Array<number>,
  link_to_describe: string
}

interface FieldOfActionWithCount {
  id: number,
  application: number,
  form_schema: JSON,
  form_ui_schema: JSON,
  answers: JSON,
  errors: Array<JSON> | null,
  actions: Array<number>,
  field_of_action_type: FieldOfActionType
  foaNumber: number
}

export default defineComponent({
  name: 'ActionDetail',
  components: {
    SubHeader,
    ReadOnlyAction,
    ActionFileUpload,
    Header,
    Form,
    FormInput,
    FormTextarea,
    Field,
    MainFormContainer,
    MainFormBottomBar,
    Alerts
  },
  setup () {
    // refs to child components in a TypeScript compatible way,
    // see https://v3.vuejs.org/guide/typescript-support.html#typing-refs.
    const actionFileUpload = ref<InstanceType<typeof ActionFileUpload>>()

    return { actionFileUpload }
  },
  data: function () {
    const schema = yup.object().shape({
      title: yup.string().required(this.$t('form_validation.required'))
    })
    return {
      collapseList: [] as Array<Collapse>,
      isLoaded: false,
      instance: {
        id: 0,
        title: '',
        short_description: '',
        goals_and_milestones: '',
        anchor_points_and_responsible_persons: '',
        involved_persons: '',
        timings_and_procedure: '',
        costs_and_financing: '',
        evaluation_and_monitoring: '',
        legal_requirement: null,
        application: 0,
        field_of_action: [],
        link_to_describe: ''
      } as ApplicationAction,
      fieldsOfAction: [] as Array<FieldOfAction>,
      actionFiles: [] as Array<ActionFile>,
      applicationInReadonlyMode: applicationInReadonlyMode,
      easyAccessFOA: {} as Record<number, FieldOfActionWithCount>,
      currentID: this.$route.params.id ? Number(this.$route.params.id) : null,
      applicationId: this.$route.params.appId ? Number(this.$route.params.appId) : null,
      schema
    }
  },
  computed: {
    application: function (): Application | null {
      return this.$store.state.currentApplication
    },
    currentApplicationPeriod: function (): ApplicationPeriod | null {
      return this.$store.state.currentApplicationPeriod
    }
  },
  mounted: function () {
    const collapseElementList = [].slice.call(document.querySelectorAll('.accordion-collapse'))
    this.collapseList = collapseElementList.map(function (collapseEl) {
      return new Collapse(collapseEl)
    })
  },
  unmounted: function () {
    for (let index = 0; index < this.collapseList.length; index++) {
      this.collapseList[index].dispose()
    }
  },
  created: async function () {
    // as the current state of the application is important here it should be fetched everytime
    let instance
    if (this.$route.params.id) {
      instance = await this.$store.dispatch('getSpecificApplicationAction', this.$route.params.id).catch(errorHelper(this.$store))
      await this.$store.dispatch('getLatestApplication', instance.application).catch(errorHelper(this.$store))
      this.actionFiles = await this.$store.dispatch('getApplicationActionFiles', instance.id).catch(errorHelper(this.$store))
      this.instance = instance
    } else {
      await this.$store.dispatch('getLatestApplication', this.applicationId).catch(errorHelper(this.$store))
    }
    this.fieldsOfAction = await this.$store.dispatch('getFieldOfActionForApplication').catch(errorHelper(this.$store))
    let foaCount = 1
    for (const foa of this.fieldsOfAction) {
      const foaCopy = JSON.parse(JSON.stringify(foa))
      foaCopy.foaNumber = foaCount
      this.easyAccessFOA[foa.id] = foaCopy
      foaCount++
    }
    this.isLoaded = true
  },
  methods: {
    storeApplicationAction: async function (values: IncompleteApplicationAction, actions: FormActions<Record<string, unknown>>) {
      const foaWithTooMuchActions = this.testSelectedFieldOfAction(values.field_of_action)
      if (foaWithTooMuchActions.length > 0) {
        let affectedFOA = `${this.$t('fields_of_action.field_no', { number: this.easyAccessFOA[foaWithTooMuchActions[0]].foaNumber })}`
        foaWithTooMuchActions.shift()
        for (const foa of foaWithTooMuchActions) {
          affectedFOA += `, ${this.$t('fields_of_action.field_no', { number: this.easyAccessFOA[foa].foaNumber })}`
        }
        const message = `${this.$t('actions.can_not_add_to_foa')} ${affectedFOA}`
        this.$store.commit('addImmediateAlert', {
          msg: message,
          type: AlertType.Danger
        })
      } else if (this.currentID) {
        await this.$store.dispatch('saveApplicationActionData', {
          applicationActionId: this.instance.id,
          actionData: values
        }).then((savedInstance) => {
          // updating the form fields here, to make sure the user sees the updated form
          // inputs (cleaned form all html-tags)
          this.setReturnedValuesToForm(actions, savedInstance)
          this.$store.commit('addImmediateAlert', {
            msg: 'actions.save_successful',
            type: AlertType.Success
          })
        }, errorHelper(this.$store))
      } else if (this.application) {
        const copiedValues = JSON.parse(JSON.stringify(values))
        copiedValues.application = this.application.id
        this.$store.dispatch('createApplicationAction', copiedValues).then((savedInstance) => {
          // updating the form fields here, to make sure the user sees the updated form
          // inputs (cleaned form all html-tags)
          this.setReturnedValuesToForm(actions, savedInstance)
          this.$store.commit('addImmediateAlert', {
            msg: 'actions.save_successful',
            type: AlertType.Success
          })
          // replacing the state here by hand to avoid pressing F5 leads to the add page here again
          // `this.$router.push` or `this.$router.replace` would lead to a reloading of the page
          // which isn't needed here
          this.currentID = savedInstance.id
          window.history.replaceState({}, document.title, `/application_actions/${savedInstance.id}`)
        }, errorHelper(this.$store))
      }
    },
    testSelectedFieldOfAction: function (fieldOfActionIds: Array<number>) {
      const errors = []
      if (this.application) {
        const constraint = getFieldOfActionActionBorders(this.application.organization)
        for (const id of fieldOfActionIds) {
          const foaToInspect = this.easyAccessFOA[id]
          if ((!(foaToInspect.actions.includes(this.instance.id)) && foaToInspect.actions.length + 1 > constraint.max) ||
          (foaToInspect.actions.includes(this.instance.id) && foaToInspect.actions.length > constraint.max)) {
            errors.push(id)
          }
        }
      }
      return errors
    },
    setReturnedValuesToForm: function (actions: FormActions<Record<string, unknown>>, savedInstance: ApplicationAction) {
      actions.setValues({
        title: savedInstance.title,
        short_description: savedInstance.short_description,
        goals_and_milestones: savedInstance.goals_and_milestones,
        anchor_points_and_responsible_persons: savedInstance.anchor_points_and_responsible_persons,
        involved_persons: savedInstance.involved_persons,
        timings_and_procedure: savedInstance.timings_and_procedure,
        costs_and_financing: savedInstance.costs_and_financing,
        evaluation_and_monitoring: savedInstance.evaluation_and_monitoring,
        legal_requirement: savedInstance.legal_requirement,
        link_to_describe: savedInstance.link_to_describe,
        field_of_action: savedInstance.field_of_action
      })
      this.instance = savedInstance
    },
    getFieldTitle: function (fieldType: FieldOfActionType): string {
      return getTitleForFieldOfActionType(fieldType)
    },
    showActionFileUploadForm: function () {
      if (this.actionFileUpload) {
        this.actionFileUpload.show()
      }
    },
    reloadFiles: async function () {
      const actionFiles = await this.$store.dispatch('getApplicationActionFiles', this.instance.id).catch(errorHelper(this.$store))
      if (actionFiles) {
        this.actionFiles = actionFiles
      }
    },
    deleteActionFile: async function (id: number) {
      if (this.application && this.currentApplicationPeriod && !applicationInReadonlyMode(this.application, this.currentApplicationPeriod)) {
        await this.$store.dispatch('deleteApplicationActionFile', id).catch(errorHelper(this.$store))
        await this.reloadFiles()
      } else {
        this.$store.commit('addImmediateAlert', { msg: 'messages.can_not_delete', type: AlertType.Warning })
      }
    }
  }
})
