
import { defineComponent } from 'vue'
import http from '@/http-common'
import { errorHelper } from '@/utils'
import { AlertType } from '@/types/internal'
import LogoTitle from '@/components/base/LogoTitle.vue'
import MainCard from '@/components/base/MainCard.vue'
import Alerts from '@/components/base/Alerts.vue'

export default defineComponent({
  name: 'ActivateAccount',
  components: {
    Alerts,
    LogoTitle,
    MainCard
  },
  data: function () {
    return {
      activationError: false
    }
  },
  created: async function () {
    const response = await http.post('auth/users/activation/', JSON.stringify({ uid: this.$route.params.uid, token: this.$route.params.token })).catch(errorHelper(this.$store, (error) => {
      if (error.response.status === 400) {
        this.$store.commit('addImmediateAlert', { msg: error.response.data.token[0], type: AlertType.Danger })
        this.activationError = true
      } else if (error.response.status === 403) {
        this.$store.commit('addPendingAlert', { msg: 'auth.already_activated', type: AlertType.Warning })
        this.$router.replace('/auth')
      }
    }, [403, 400]))
    if (response && response.status && response.status === 204) {
      this.$store.commit('addPendingAlert', { msg: 'auth.activation_successful', type: AlertType.Success })
      await this.$router.replace('/auth')
    }
  }
})
