
import {
  ApplicationAction,
  ApplicationForm,
  ApplicationPeriod,
  FieldOfAction,
  FieldOfActionType,
  FormType,
  Organization
} from '@/types/descript_application_portal_rest'
import { errorHelper, getApplicationPeriod, getShortNameForFieldOfAction } from '@/utils'
import { defineComponent } from 'vue'
import { PrintFormRenderComponent } from '@descript/vue-js-json-forms-bootstrap-renderer'
import ReadOnlyAction from '@/components/application_action/ReadOnlyAction.vue'
import MainFormBottomBar from '@/components/forms/MainFormBottomBar.vue'
import { ScrollSpy } from 'bootstrap'

export default defineComponent({
  name: 'PrintApplicationView',
  components: {
    ReadOnlyAction,
    PrintFormRenderComponent,
    MainFormBottomBar
  },
  data: function () {
    return {
      hasCurrentApplication: false,
      localFormTypes: FormType,
      scrollSpy: null as unknown as ScrollSpy,
      applicationId: this.$route.params.id ? Number(this.$route.params.id) : null
    }
  },
  computed: {
    applicationForms (): Array<ApplicationForm> {
      return this.$store.state.applicationForms
    },
    fieldsOfActions: function (): Array<FieldOfAction> {
      return this.$store.state.fieldsOfAction
    },
    applicationActions: function (): Array<ApplicationAction> {
      return this.$store.state.applicationActions
    },
    organization (): Organization | null {
      return this.$store.state.organization
    },
    currentApplicationPeriod (): ApplicationPeriod | null {
      return this.$store.state.currentApplicationPeriod
    }
  },
  created: async function () {
    await this.$store.dispatch('getLatestApplication', this.applicationId).catch(errorHelper(this.$store))
    await this.$store.dispatch('getFormsForApplication').catch(errorHelper(this.$store))
    await this.$store.dispatch('getFieldOfActionForApplication').catch(errorHelper(this.$store))
    await this.$store.dispatch('getCurrentActions').catch(errorHelper(this.$store))
    if (this.applicationForms.length !== 0 && this.fieldsOfActions.length !== 0) {
      this.hasCurrentApplication = true
      // Initialize Bootstrap scrollspy
      // for some reason the initializing of the scrollspy has to be delayed, because without it the
      // reference for the instance wouldn't be there. Weirdly the scrollspy would still work properly
      // but an error would be printed to the console
      // Another weird part of this behavior is, that the test which mounts the component and looks at the
      // content of the created html, triggers a different error than the one displayed in the console of the browser
      window.setTimeout(this.initScrollspy, 1)
    }
  },
  unmounted: function () {
    if (this.scrollSpy) {
      this.scrollSpy.dispose()
    }
  },
  methods: {
    getFormTitle (formType: FormType) {
      switch (formType) {
        case FormType.FormTypeBasicUnderstanding:
          return 'action_fields.our_base_values'
        case FormType.FormTypeGeneralInformation:
          return 'action_fields.basic_information'
        case FormType.FormTypeStatisticalInformation:
          return 'action_fields.statistical_information'
        default:
          return ''
      }
    },
    getShortNameOfField: function (fieldType: FieldOfActionType) {
      return getShortNameForFieldOfAction(fieldType)
    },
    getCorrespondingFormForFormType (formType: FormType, applicationForms: Array<ApplicationForm>): ApplicationForm|undefined {
      for (const applicationForm of applicationForms) {
        if (applicationForm.form_type === formType) {
          return applicationForm
        }
      }
    },
    openPrintDialog: function () {
      window.print()
    },
    getCurrentApplicationPeriod: function (applicationPeriod: ApplicationPeriod | null) {
      return getApplicationPeriod(applicationPeriod)
    },
    initScrollspy: function () : ScrollSpy | null {
      this.scrollSpy = new ScrollSpy(document.body, {
        target: '#navbar-print-view',
        offset: 80
      })
      return this.scrollSpy
    }
  }
})
