import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "navbar-nav mx-auto" }
const _hoisted_3 = { class: "nav-item" }
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = { class: "nav-item ms-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LocaleChooser = _resolveComponent("LocaleChooser")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(["page-footer navbar navbar-expand", [_ctx.position, _ctx.noPrint ? 'd-print-none' : '']])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createVNode(_component_router_link, {
            to: "/imprint",
            class: "nav-link small"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('meta.pageTitles.imprint')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: "/privacy",
            class: "nav-link small"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('meta.pageTitles.privacy')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", _hoisted_5, [
          _createVNode(_component_LocaleChooser, { class: "form-select-sm mt-1" })
        ])
      ])
    ])
  ], 2))
}