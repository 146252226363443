import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.sticky)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentAlerts, (alert) => {
      return (_openBlock(), _createElementBlock("div", {
        key: alert.msg + alert.type + _ctx.activeLocale,
        class: _normalizeClass(["alert alert-dismissible", 'alert-' + alert.type]),
        role: "alert"
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t(alert.msg)) + " ", 1),
        _createElementVNode("button", {
          class: "btn btn-close",
          onClick: ($event: any) => (_ctx.removeAlert(alert))
        }, null, 8, _hoisted_1)
      ], 2))
    }), 128))
  ], 2))
}