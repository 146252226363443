
import { defineComponent } from 'vue'
import MainOverviewContainer from '@/components/base/MainOverviewContainer.vue'
import { ApplicationPeriod, getPlaceholderApplication, Organization } from '@/types/descript_application_portal_rest'
import { errorHelper, getApplicationPeriod, getApplicationTime, getAreaOfCompany } from '@/utils'
import ValidityOfCertificateComponent from '@/components/application/ValidityOfCertificateComponent.vue'
import { Filter } from '@/types/internal'
import FilterBar from '@/components/base/FilterBar.vue'

export default defineComponent({
  name: 'AdminOrganizationOverview',
  components: {
    FilterBar,
    MainOverviewContainer,
    ValidityOfCertificateComponent
  },
  computed: {
    activeLocale: function (): string {
      return this.$i18n.locale
    },
    organizationList: function (): Array<Organization> {
      return this.$store.state.allOrganizations[this.adminOrganizationOverviewListKey].instances
    },
    periodList: function (): Record<number, ApplicationPeriod> {
      return this.$store.state.applicationPeriods
    },
    availableFilters: function (): Array<Filter> {
      return this.$store.state.availableOrganizationFilters
    },
    activeFilters: function (): Array<Filter> {
      return this.$store.state.allOrganizations[this.adminOrganizationOverviewListKey].filters
    }
  },
  data: function () {
    return {
      isLoaded: false,
      adminOrganizationOverviewListKey: 'AdminOrganizationOverview'
    }
  },
  created: async function () {
    this.$store.state.currentApplication = getPlaceholderApplication()
    await this.$store.dispatch('getAvailableOrganizationFilter')
    this.$store.commit('ensureOrganizationList', this.adminOrganizationOverviewListKey)
    await this.$store.dispatch('getAllOrganizations', this.adminOrganizationOverviewListKey).catch(errorHelper(this.$store))
    this.isLoaded = true
  },
  methods: {
    getAreaOfCompany,
    getApplicationTime,
    getApplicationPeriod,
    removeFilter: function (filter: Filter) {
      this.$store.dispatch('removeAdminOrganizationFilter', { listKey: this.adminOrganizationOverviewListKey, filter: filter }).catch(errorHelper(this.$store))
    },
    addFilter: function (filter: Filter) {
      this.$store.dispatch('addAdminOrganizationFilter', { listKey: this.adminOrganizationOverviewListKey, filter: filter, onlyOnListInit: false }).catch(errorHelper(this.$store))
    },
    resetAllFilters: async function () {
      await this.$store.dispatch('resetAdminOrganizationFilter', this.adminOrganizationOverviewListKey)
    }
  }
})
