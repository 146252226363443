
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalFooter',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    className: {
      type: String,
      default: 'modal-footer d-flex justify-content-between'
    }
  }
})
