import { version } from '../../package.json'

export const isProduction = process.env.NODE_ENV === 'production'

let url = ''
if (process.env.VUE_APP_TEQ_ENDPOINT) {
  url = process.env.VUE_APP_TEQ_ENDPOINT
} else {
  url = isProduction ? 'https://api.total-e-quality.de/' : 'http://localhost:8000/'
}

export const axiosConfiguration = {
  baseURL: url,
  headers: {
    'Content-type': 'application/json'
  },
  withCredentials: true
}

export const matomoConfiguration = {
  host: 'https://stats.descript.de/',
  siteId: 142,
  trackerFileName: 'matomo',
  enableLinkTracking: true,
  disableCookies: true,
  requireConsent: false
}

export const sentryConfiguration = {
  // We have to disable autoSessionTracking here cause our self-hosted
  // Sentry instance running v9.x does not support session tracking.
  // We need to upgrade first. https://github.com/getsentry/sentry-javascript/issues/2859
  autoSessionTracking: false,
  dsn: isProduction
    ? 'https://bbf6f9133f6440b48283f9c8258e3981@sentry.descript.de/11'
    : 'https://c0031e45c884472caf197d7c5c04cf99@sentry.descript.de/12',
  release: 'vue-js-teq-application@' + version
}
