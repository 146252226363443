<template>
  <Header/>
  <main class="container">
    <Alerts sticky="sticky-below-page-nav"/>
    <!-- MainOverviewContainer: Title -->
    <div class="row justify-content-center">
      <div class="col-lg-8 text-center">
        <slot name="title"></slot>
      </div>
    </div>
    <!-- MainOverviewContainer: Content -->
    <slot name="content"></slot>
  </main>
  <Footer/>
</template>

<script>
import { defineComponent } from 'vue'
import Footer from '@/components/base/Footer.vue'
import Header from '@/components/base/Header.vue'
import Alerts from '@/components/base/Alerts.vue'

export default defineComponent({
  name: 'MainOverviewContainer',
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Header,
    Footer,
    Alerts
  }
})
</script>

<style scoped>
</style>
