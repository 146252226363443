
import { defineComponent } from 'vue'
import MainOverviewContainer from '@/components/base/MainOverviewContainer.vue'
import Card from '@/components/base/Card.vue'

export default defineComponent({
  name: 'ConditionsOfParticipation',
  components: {
    MainOverviewContainer,
    Card
  }
})
