import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "outer-form" }
const _hoisted_2 = { id: "register-head" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = {
  class: "accordion accordion-flush",
  id: "accordion-actions",
  "data-bs-offset": "0"
}
const _hoisted_5 = { class: "accordion-item" }
const _hoisted_6 = {
  class: "accordion-header",
  id: "accordion-header-registration_organization_type"
}
const _hoisted_7 = {
  class: "accordion-button",
  type: "button",
  "data-bs-toggle": "collapse",
  "data-bs-target": "#collapse-registration-sector",
  "aria-expanded": "true",
  "aria-controls": "collapse-registration-sector"
}
const _hoisted_8 = {
  class: "accordion-collapse collapse show",
  id: "collapse-registration-sector",
  "aria-labelledby": "collapse-registration-sector"
}
const _hoisted_9 = { class: "h4 mb-3" }
const _hoisted_10 = { class: "accordion-item" }
const _hoisted_11 = {
  class: "accordion-header",
  id: "accordion-header-registration-name"
}
const _hoisted_12 = {
  class: "accordion-button",
  type: "button",
  "data-bs-toggle": "collapse",
  "data-bs-target": "#collapse-registration-name",
  "aria-expanded": "true",
  "aria-controls": "collapse-registration-name"
}
const _hoisted_13 = {
  class: "accordion-collapse collapse show",
  id: "collapse-registration-name",
  "aria-labelledby": "collapse-registration-name"
}
const _hoisted_14 = { class: "h4 mb-3" }
const _hoisted_15 = { class: "h4 mb-3" }
const _hoisted_16 = { class: "mb-3" }
const _hoisted_17 = { class: "form-check form-check-inline" }
const _hoisted_18 = {
  class: "form-check-label",
  for: "current_certificate_is_valid_yes"
}
const _hoisted_19 = { class: "form-check form-check-inline" }
const _hoisted_20 = {
  class: "form-check-label",
  for: "current_certificate_is_valid_no"
}
const _hoisted_21 = { class: "h4 mb-3" }
const _hoisted_22 = { class: "accordion-item" }
const _hoisted_23 = {
  class: "accordion-header",
  id: "accordion-header-registration-user"
}
const _hoisted_24 = {
  class: "accordion-button",
  type: "button",
  "data-bs-toggle": "collapse",
  "data-bs-target": "#collapse-registration-user",
  "aria-expanded": "true",
  "aria-controls": "collapse-registration-user"
}
const _hoisted_25 = {
  class: "accordion-collapse collapse show",
  id: "collapse-registration-user",
  "aria-labelledby": "collapse-registration-name"
}
const _hoisted_26 = { class: "row" }
const _hoisted_27 = { class: "col" }
const _hoisted_28 = { class: "h4 mb-3" }
const _hoisted_29 = { class: "col" }
const _hoisted_30 = { class: "h4 mb-3" }
const _hoisted_31 = { class: "row" }
const _hoisted_32 = { class: "col" }
const _hoisted_33 = { class: "h4 mb-3" }
const _hoisted_34 = { class: "col" }
const _hoisted_35 = { class: "h4 mb-3" }
const _hoisted_36 = { class: "center-block" }
const _hoisted_37 = {
  class: "btn btn-secondary",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alerts = _resolveComponent("Alerts")!
  const _component_FormSelect = _resolveComponent("FormSelect")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_Field = _resolveComponent("Field")!
  const _component_FormCheckbox = _resolveComponent("FormCheckbox")!
  const _component_Form = _resolveComponent("Form")!
  const _component_MainFormContainer = _resolveComponent("MainFormContainer")!

  return (_openBlock(), _createBlock(_component_MainFormContainer, {
    title: _ctx.$t('text.application_portal')
  }, {
    form: _withCtx(() => [
      _createVNode(_component_Alerts, { sticky: "sticky-below-page-nav" }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('text.register')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('registration.register_subtext')), 1)
        ]),
        _createVNode(_component_Form, {
          onSubmit: _ctx.finishRegistration,
          "validation-schema": _ctx.registrationValidationSchema,
          "initial-values": _ctx.instance,
          id: "js-registration-form"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("button", _hoisted_7, [
                    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('registration.sector')), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('registration.sector_description')), 1),
                  _createVNode(_component_FormSelect, {
                    options: _ctx.organizationTypeOptions,
                    type: "select",
                    name: "organization_type",
                    "empty-value": 0,
                    "is-multiple": false
                  }, null, 8, ["options"])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("button", _hoisted_12, [
                    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('registration.name_title')), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t('registration.name')), 1),
                  _createVNode(_component_FormInput, {
                    type: "text",
                    name: "name"
                  }),
                  _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('registration.current_certificate')), 1),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_Field, {
                        type: "radio",
                        value: true,
                        name: "current_certificate_is_valid",
                        id: "current_certificate_is_valid_yes",
                        class: "form-check-input"
                      }),
                      _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('text.yes')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createVNode(_component_Field, {
                        type: "radio",
                        value: false,
                        name: "current_certificate_is_valid",
                        id: "current_certificate_is_valid_no",
                        class: "form-check-input"
                      }),
                      _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('text.no')), 1)
                    ])
                  ]),
                  _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.$t('registration.organization_size')), 1),
                  _createVNode(_component_FormSelect, {
                    options: _ctx.organizationSizeOptions,
                    type: "select",
                    name: "organization_size",
                    "empty-value": 0,
                    "is-multiple": false
                  }, null, 8, ["options"])
                ])
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("button", _hoisted_24, [
                    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('registration.user_title')), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("p", _hoisted_28, _toDisplayString(_ctx.$t('registration.user_name')), 1),
                      _createVNode(_component_FormInput, {
                        type: "text",
                        name: "username",
                        "help-text": _ctx.$t('registration.help_username')
                      }, null, 8, ["help-text"])
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.$t('registration.email')), 1),
                      _createVNode(_component_FormInput, {
                        type: "text",
                        name: "email",
                        "help-text": _ctx.$t('registration.help_email')
                      }, null, 8, ["help-text"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("p", _hoisted_33, _toDisplayString(_ctx.$t('auth.login_password')), 1),
                      _createVNode(_component_FormInput, {
                        type: "password",
                        name: "password"
                      })
                    ]),
                    _createElementVNode("div", _hoisted_34, [
                      _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.$t('auth.registration_password_repeat')), 1),
                      _createVNode(_component_FormInput, {
                        type: "password",
                        name: "re_password"
                      })
                    ])
                  ]),
                  _createVNode(_component_FormCheckbox, {
                    name: "accepting_terms",
                    label: _ctx.acceptingTermsLabel()
                  }, null, 8, ["label"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("button", _hoisted_37, _toDisplayString(_ctx.$t('registration.finish')), 1)
            ])
          ]),
          _: 1
        }, 8, ["onSubmit", "validation-schema", "initial-values"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}