import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-lg-4" }
const _hoisted_3 = { class: "input-group mb-4" }
const _hoisted_4 = ["name", "id", "aria-describedby", "placeholder"]
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control bg-white",
          name: _ctx.name,
          id: _ctx.name,
          "aria-describedby": _ctx.helpId,
          placeholder: _ctx.placeholder ? _ctx.placeholder : _ctx.label,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.term) = $event)),
          onKeyup: [
            _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.doSearch && _ctx.doSearch(...args)), ["enter"])),
            _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.resetSearch && _ctx.resetSearch(...args)), ["esc"]))
          ]
        }, null, 40, _hoisted_4), [
          [_vModelText, _ctx.term]
        ]),
        (_ctx.showResetBtn && _ctx.term)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              "btn-style": "btn-outline-danger bg-white",
              "btn-type": "button",
              "show-icon": "x",
              "icon-only": "",
              onClick: _ctx.resetSearch,
              id: "reset"
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Button, {
          "btn-style": "btn-primary",
          "btn-type": "button",
          "show-icon": "search",
          "icon-only": "",
          onClick: _ctx.doSearch,
          id: "search"
        }, null, 8, ["onClick"]),
        (_ctx.helpText)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              id: _ctx.helpId,
              class: "form-text"
            }, _toDisplayString(_ctx.helpText), 9, _hoisted_5))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}