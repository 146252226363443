
import { defineComponent } from 'vue'
import { Form } from 'vee-validate'
import FormInput from '@/components/forms/FormInput.vue'
import MainCard from '@/components/base/MainCard.vue'
import * as yup from 'yup'
import http from '@/http-common.ts'
import Alerts from '@/components/base/Alerts.vue'
import { errorHelper } from '@/utils'
import { AlertType } from '@/types/internal'
import LogoTitle from '@/components/base/LogoTitle.vue'

export default defineComponent({
  name: 'UserLogin',
  components: {
    Alerts,
    Form,
    FormInput,
    MainCard,
    LogoTitle
  },
  data: function () {
    const schema = yup.object().shape({
      username: yup.string()
        .required(this.$t('form_validation.required')),
      password: yup.string()
        .required(this.$t('form_validation.required'))
    })

    return {
      schema: schema,
      username: '',
      password: '',
      goingToAdmin: !!localStorage.getItem('willLoginIntoAdmin')
    }
  },
  methods: {
    signIn (values: Record<string, string>) {
      http.post('auth/token/login/', values).then(
        (response) => {
          localStorage.setItem('authToken', response.data.auth_token)
          localStorage.setItem('username', this.username)
          if (this.goingToAdmin) {
            localStorage.setItem('isAdmin', 'Yes')
            this.$router.push('/admin')
          } else {
            this.$router.push('/overview')
          }
        }, (errorHelper(this.$store)))
        .catch((response) => {
          this.$store.commit('addImmediateAlert', { msg: response.message, type: AlertType.Danger })
        })
    },
    removeToAdmin () {
      localStorage.removeItem('willLoginIntoAdmin')
    }
  }
})
