import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "table table-hover" }
const _hoisted_5 = { class: "d-flex align-items-center mb-3" }
const _hoisted_6 = { class: "dropdown-menu" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ApplicationStateComponent = _resolveComponent("ApplicationStateComponent")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_MainOverviewContainer = _resolveComponent("MainOverviewContainer")!

  return (_openBlock(), _createBlock(_component_MainOverviewContainer, null, {
    title: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('text.admin_organization_application_overview')), 1)
    ]),
    content: _withCtx(() => [
      (_ctx.isLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_SearchInput, {
                label: _ctx.$t('text.search'),
                placeholder: _ctx.$t('search.applications'),
                name: "search",
                onDoSearch: _ctx.doSearch,
                onResetSearch: _ctx.resetSearch
              }, null, 8, ["label", "placeholder", "onDoSearch", "onResetSearch"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("table", _hoisted_4, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('application.organization')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('application.organization_type')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('application.application_period')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('application.state')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('application.last_modified')), 1)
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.applicationList, (application) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: application.id + _ctx.activeLocale
                    }, [
                      _createElementVNode("td", null, [
                        _createVNode(_component_router_link, {
                          to: { name: 'ApplicationOverview', params: { id: application.id }}
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(application.organization.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$t(_ctx.getAreaOfCompany(application.organization))), 1),
                      _createElementVNode("td", null, _toDisplayString(_ctx.getApplicationPeriod(_ctx.periodList[application.application_period])), 1),
                      _createElementVNode("td", null, [
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_ApplicationStateComponent, {
                            state: application.state
                          }, null, 8, ["state"]),
                          _createVNode(_component_Dropdown, {
                            class: "btn btn-sm dropdown-toggle",
                            "as-button": true,
                            type: "button",
                            id: 'application-state-changer-' + application.id,
                            "aria-expanded": "false"
                          }, {
                            main: _withCtx(() => [
                              _createElementVNode("ul", _hoisted_6, [
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", {
                                    href: "",
                                    onClick: _withModifiers(($event: any) => (_ctx.setApplicationState(application.id, _ctx.localApplicationStates.ApplicationStateStarted)), ["prevent"]),
                                    class: "dropdown-item"
                                  }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('text.set_state')) + " ", 1),
                                    _createVNode(_component_ApplicationStateComponent, {
                                      state: _ctx.localApplicationStates.ApplicationStateStarted
                                    }, null, 8, ["state"])
                                  ], 8, _hoisted_7)
                                ]),
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", {
                                    href: "",
                                    onClick: _withModifiers(($event: any) => (_ctx.setApplicationState(application.id, _ctx.localApplicationStates.ApplicationStateInProgress)), ["prevent"]),
                                    class: "dropdown-item"
                                  }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('text.set_state')) + " ", 1),
                                    _createVNode(_component_ApplicationStateComponent, {
                                      state: _ctx.localApplicationStates.ApplicationStateInProgress
                                    }, null, 8, ["state"])
                                  ], 8, _hoisted_8)
                                ]),
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", {
                                    href: "",
                                    onClick: _withModifiers(($event: any) => (_ctx.setApplicationState(application.id, _ctx.localApplicationStates.ApplicationStateWaitingForFeedback)), ["prevent"]),
                                    class: "dropdown-item"
                                  }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('text.set_state')) + " ", 1),
                                    _createVNode(_component_ApplicationStateComponent, {
                                      state: _ctx.localApplicationStates.ApplicationStateWaitingForFeedback
                                    }, null, 8, ["state"])
                                  ], 8, _hoisted_9)
                                ]),
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", {
                                    href: "",
                                    onClick: _withModifiers(($event: any) => (_ctx.setApplicationState(application.id, _ctx.localApplicationStates.ApplicationStateAdditionalInfoNeeded)), ["prevent"]),
                                    class: "dropdown-item"
                                  }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('text.set_state')) + " ", 1),
                                    _createVNode(_component_ApplicationStateComponent, {
                                      state: _ctx.localApplicationStates.ApplicationStateAdditionalInfoNeeded
                                    }, null, 8, ["state"])
                                  ], 8, _hoisted_10)
                                ]),
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", {
                                    href: "",
                                    onClick: _withModifiers(($event: any) => (_ctx.setApplicationState(application.id, _ctx.localApplicationStates.ApplicationStateSuccessful)), ["prevent"]),
                                    class: "dropdown-item"
                                  }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('text.set_state')) + " ", 1),
                                    _createVNode(_component_ApplicationStateComponent, {
                                      state: _ctx.localApplicationStates.ApplicationStateSuccessful
                                    }, null, 8, ["state"])
                                  ], 8, _hoisted_11)
                                ]),
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", {
                                    href: "",
                                    onClick: _withModifiers(($event: any) => (_ctx.setApplicationState(application.id, _ctx.localApplicationStates.ApplicationStateRejected)), ["prevent"]),
                                    class: "dropdown-item"
                                  }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('text.set_state')) + " ", 1),
                                    _createVNode(_component_ApplicationStateComponent, {
                                      state: _ctx.localApplicationStates.ApplicationStateRejected
                                    }, null, 8, ["state"])
                                  ], 8, _hoisted_12)
                                ]),
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", {
                                    href: "",
                                    onClick: _withModifiers(($event: any) => (_ctx.setApplicationState(application.id, _ctx.localApplicationStates.ApplicationStateInEvaluation)), ["prevent"]),
                                    class: "dropdown-item"
                                  }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('text.set_state')) + " ", 1),
                                    _createVNode(_component_ApplicationStateComponent, {
                                      state: _ctx.localApplicationStates.ApplicationStateInEvaluation
                                    }, null, 8, ["state"])
                                  ], 8, _hoisted_13)
                                ])
                              ])
                            ]),
                            _: 2
                          }, 1032, ["id"])
                        ])
                      ]),
                      _createElementVNode("td", null, [
                        (application.last_modified)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.getDate(application.last_modified, _ctx.activeLocale)), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_15, "-"))
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}