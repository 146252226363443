
import { defineComponent, ref } from 'vue'
import Header from '@/components/base/Header.vue'
import MainFormContainer from '@/components/forms/MainFormContainer.vue'
import { Form, FormActions } from 'vee-validate'
import FormInput from '@/components/forms/FormInput.vue'
import {
  OrganizationSize
} from '@/types/descript_application_portal_rest'
import Alerts from '@/components/base/Alerts.vue'
import FormSelect from '@/components/forms/FormSelect.vue'
import FormFileInput from '@/components/forms/FormFileInput.vue'
import { errorHelper } from '@/utils'
import { AlertType } from '@/types/internal'

export default defineComponent({
  name: 'OrganizationDetail',
  components: {
    Alerts,
    Header,
    MainFormContainer,
    Form,
    FormInput,
    FormSelect,
    FormFileInput
  },
  setup () {
    const logoFile = ref<InstanceType<typeof FormFileInput>>()

    return { logoFile }
  },
  data: function () {
    const organizationSizeOptions = [
      { id: OrganizationSize.OrganizationSizeSmall, label: this.$t('registration.organization_size_small') },
      { id: OrganizationSize.OrganizationSizeMiddle, label: this.$t('registration.organization_size_middle') },
      { id: OrganizationSize.OrganizationSizeLarge, label: this.$t('registration.organization_size_large') }
    ]
    return {
      organizationId: 0,
      instance: {
        name: '',
        logo: '',
        organization_size: 0
      },
      organizationSizeOptions: organizationSizeOptions
    }
  },
  methods: {
    updateOrganization: async function (values: any, actions: FormActions<Record<string, unknown>>) {
      const formData = new FormData()
      formData.append('name', values.name)
      formData.append('organization_size', values.organization_size.toString())
      if (typeof values.logo === 'object') {
        formData.append('logo', values.logo[0])
      }
      const savedInstance = await this.$store.dispatch('saveOrganizationData', {
        organizationId: this.organizationId,
        formData: formData
      }).catch(errorHelper(this.$store, (error) => {
        actions.setErrors(error.response.data)
      }))
      if (savedInstance) {
        actions.setValues({
          name: savedInstance.name,
          organization_size: savedInstance.organization_size,
          logo: ''
        })
        this.instance = savedInstance
        if (this.logoFile) {
          this.logoFile.reset()
        }
        this.$store.commit('addImmediateAlert', { msg: 'organization.organization_saved', type: AlertType.Success })
      }
    }
  },
  created: async function () {
    const organization = await this.$store.dispatch('getOrganization').catch(errorHelper(this.$store))
    if (organization) {
      this.organizationId = organization.id
      this.instance = organization
    }
  }
})
