import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('dropdown ' + _ctx.containerStyle)
  }, [
    (_ctx.asButton)
      ? (_openBlock(), _createElementBlock("button", _mergeProps({
          key: 0,
          id: _ctx.id,
          "data-bs-toggle": "dropdown"
        }, _ctx.$attrs), [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ])
        ], 16, _hoisted_1))
      : (_openBlock(), _createElementBlock("a", _mergeProps({
          key: 1,
          href: "#",
          id: _ctx.id,
          "data-bs-toggle": "dropdown"
        }, _ctx.$attrs), [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ])
        ], 16, _hoisted_2)),
    _renderSlot(_ctx.$slots, "main")
  ], 2))
}