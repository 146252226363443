import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "h4 mb-3" }
const _hoisted_2 = { class: "h4 mb-3" }
const _hoisted_3 = { class: "h4 mb-3" }
const _hoisted_4 = {
  key: 0,
  class: "d-flex align-items-center mb-3"
}
const _hoisted_5 = { class: "mb-0 me-2 text-muted" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "center-block" }
const _hoisted_9 = {
  class: "btn btn-secondary",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Alerts = _resolveComponent("Alerts")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_FormSelect = _resolveComponent("FormSelect")!
  const _component_FormFileInput = _resolveComponent("FormFileInput")!
  const _component_Form = _resolveComponent("Form")!
  const _component_MainFormContainer = _resolveComponent("MainFormContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createVNode(_component_MainFormContainer, {
      title: _ctx.$t('organization.organization_details')
    }, {
      form: _withCtx(() => [
        _createVNode(_component_Alerts, { sticky: "sticky-below-page-nav" }),
        _createVNode(_component_Form, {
          onSubmit: _ctx.updateOrganization,
          "initial-values": _ctx.instance,
          enctype: "multipart/form-data"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('registration.name')), 1),
            _createVNode(_component_FormInput, {
              type: "text",
              name: "name"
            }),
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('registration.organization_size')), 1),
            _createVNode(_component_FormSelect, {
              options: _ctx.organizationSizeOptions,
              type: "select",
              name: "organization_size",
              "empty-value": 0,
              "is-multiple": false
            }, null, 8, ["options"]),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('organization.organization_logo')), 1),
            (_ctx.instance.logo)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('organization.current_logo_title')), 1),
                  _createElementVNode("img", {
                    src: _ctx.instance.logo,
                    class: "img-fluid img-logo-title",
                    alt: _ctx.$t('organization.logo_alt')
                  }, null, 8, _hoisted_6)
                ]))
              : (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('organization.missing_logo')), 1)),
            _createVNode(_component_FormFileInput, {
              ref: "logoFile",
              class: "mb-4",
              name: "logo",
              accept: ".eps,.svg,.tif,.tiff",
              "help-text": _ctx.$t('organization.logo_formats_help_text')
            }, null, 8, ["help-text"]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("button", _hoisted_9, _toDisplayString(_ctx.$t('organization.save_organization')), 1)
            ])
          ]),
          _: 1
        }, 8, ["onSubmit", "initial-values"])
      ]),
      _: 1
    }, 8, ["title"])
  ], 64))
}