import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "spinner-border spinner-border-sm",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalTitle = _resolveComponent("ModalTitle")!
  const _component_ModalHeader = _resolveComponent("ModalHeader")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_FormFileInput = _resolveComponent("FormFileInput")!
  const _component_ModalBody = _resolveComponent("ModalBody")!
  const _component_ModalFooter = _resolveComponent("ModalFooter")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "data-bs-backdrop": "static",
    "data-bs-keyboard": "false",
    id: "file-upload",
    ref: "uploadApplicationFormFileModal"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ModalHeader, {
        disabled: _ctx.uploadProcessing,
        onModalClosed: _ctx.hideModal
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ModalTitle, {
            title: _ctx.$t('actions.file_upload')
          }, null, 8, ["title"])
        ]),
        _: 1
      }, 8, ["disabled", "onModalClosed"]),
      _createVNode(_component_Form, {
        ref: "uploadForm",
        onSubmit: _ctx.uploadFile,
        "validation-schema": _ctx.uploadSchema,
        id: "upload-form"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ModalBody, { class: "pt-0" }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('actions.upload_explanation')), 1),
              _createVNode(_component_FormInput, {
                type: "text",
                label: _ctx.$t('text.title'),
                name: "name"
              }, null, 8, ["label"]),
              _createVNode(_component_FormFileInput, {
                ref: "inputFile",
                name: "uploaded_file"
              }, null, 512)
            ]),
            _: 1
          }),
          _createVNode(_component_ModalFooter, null, {
            default: _withCtx(() => [
              _createElementVNode("button", {
                class: "btn btn-secondary",
                type: "submit",
                disabled: _ctx.uploadProcessing
              }, [
                (_ctx.uploadProcessing)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('actions.start_upload')), 1)
              ], 8, _hoisted_1),
              _createElementVNode("button", {
                class: "btn btn-outline-primary",
                "data-bs-dismiss": "modal",
                type: "reset",
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.hideModal && _ctx.hideModal(...args)), ["prevent"])),
                disabled: _ctx.uploadProcessing
              }, _toDisplayString(_ctx.$t('text.abort')), 9, _hoisted_3)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onSubmit", "validation-schema"])
    ]),
    _: 1
  }, 512))
}