import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { isAuthenticated } from '@/utils'
import Imprint from '@/views/Imprint.vue'
import Privacy from '@/views/Privacy.vue'
import UserLogin from '@/views/auth/UserLogin.vue'
import { i18n } from '@/i18n'
import Overview from '@/views/Overview.vue'
import ConditionsOfParticipation from '@/views/information/ConditionsOfParticipation.vue'
import FormView from '@/views/form_views/FormView.vue'
import ActionsOverview from '@/views/actions/ActionsOverview.vue'
import ActionDetail from '@/views/actions/ActionDetail.vue'
import FieldOfActionOverview from '@/views/fields_of_action/FieldOfActionOverview.vue'
import FieldOfActionDetails from '@/views/fields_of_action/FieldOfActionDetails.vue'
import SettingsOverview from '@/views/user_settings/SettingsOverview.vue'
import ChangePassword from '@/views/user_settings/ChangePassword.vue'
import RequestPasswordReset from '@/views/auth/RequestPasswordReset.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'
import PrintView from '@/views/form_views/PrintApplicationView.vue'
import AdminApplicationOverview from '@/views/admin/AdminApplicationOverview.vue'
import Faq from '@/views/information/Faq.vue'
import Contact from '@/views/information/Contact.vue'
import Conditions from '@/views/information/Conditions.vue'
import Instruction from '@/views/information/Instruction.vue'
import Membership from '@/views/information/Membership.vue'
import Registration from '@/views/auth/Registration.vue'
import ActivateAccount from '@/views/auth/ActivateAccount.vue'
import OrganizationDetail from '@/views/user_settings/OrganizationDetail.vue'
import AdminOrganizationOverview from '@/views/admin/AdminOrganizationOverview.vue'
import UserApplicationOverview from '@/views/UserApplicationOverview.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    redirect: () => {
      if (isAuthenticated()) {
        return '/overview'
      }

      return '/auth'
    }
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint,
    meta: {
      title: 'meta.pageTitles.imprint'
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      title: 'meta.pageTitles.privacy'
    }
  },
  {
    path: '/auth',
    name: 'UserLogin',
    component: UserLogin,
    meta: {
      title: 'meta.pageTitles.user_login'
    }
  },
  {
    path: '/auth/reset',
    name: 'RequestPasswordReset',
    component: RequestPasswordReset,
    meta: {
      title: 'meta.pageTitles.reset_password_request'
    }
  },
  {
    path: '/auth/reset_confirm/:uid/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: 'meta.pageTitles.reset_password'
    }
  },
  {
    path: '/overview',
    name: 'UserApplicationOverview',
    component: UserApplicationOverview,
    meta: {
      title: 'meta.pageTitles.overview'
    }
  },
  {
    path: '/application/:id',
    name: 'ApplicationOverview',
    component: Overview,
    meta: {
      title: 'meta.pageTitles.overview'
    }
  },
  {
    path: '/conditions_of_participation',
    name: 'ConditionsOfParticipation',
    component: ConditionsOfParticipation,
    meta: {
      title: 'meta.pageTitles.conditions_of_participation'
    }
  },
  {
    path: '/conditions_of_participation/conditions',
    name: 'Conditions',
    component: Conditions,
    meta: {
      title: 'meta.pageTitles.conditions'
    }
  },
  {
    path: '/conditions_of_participation/instruction',
    name: 'Instruction',
    component: Instruction,
    meta: {
      title: 'meta.pageTitles.instructions'
    }
  },
  {
    path: '/conditions_of_participation/membership',
    name: 'Membership',
    component: Membership,
    meta: {
      title: 'meta.pageTitles.membership'
    }
  },
  {
    path: '/form/:id',
    name: 'FormView',
    component: FormView,
    meta: {
      title: 'meta.pageTitles.form_view'
    }
  },
  {
    path: '/application_actions_overview/:id',
    name: 'ApplicationActionOverview',
    component: ActionsOverview,
    meta: {
      title: 'meta.pageTitles.actions_overview'
    }
  },
  {
    path: '/application_actions/:id',
    name: 'ApplicationActionDetail',
    component: ActionDetail,
    meta: {
      title: 'meta.pageTitles.action_detail'
    }
  },
  {
    path: '/application_actions/add/:appId',
    name: 'ApplicationActionAdd',
    component: ActionDetail,
    meta: {
      title: 'meta.pageTitles.action_detail'
    }
  },
  {
    path: '/field_of_action_overview/:id',
    name: 'FieldOfActionOverview',
    component: FieldOfActionOverview,
    meta: {
      title: 'meta.pageTitles.field_of_action_overview'
    }
  },
  {
    path: '/field_of_action/:id',
    name: 'FieldOfActionDetails',
    component: FieldOfActionDetails,
    meta: {
      title: 'meta.pageTitles.field_of_action_detail'
    }
  },
  {
    path: '/user_settings',
    name: 'SettingsOverview',
    component: SettingsOverview,
    meta: {
      title: 'meta.pageTitles.settings_overview'
    }
  },
  {
    path: '/user_settings/change_password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      title: 'meta.pageTitles.change_password'
    }
  },
  {
    path: '/print_view/:id',
    name: 'PrintView',
    component: PrintView,
    meta: {
      title: 'meta.pageTitles.print_view'
    }
  },
  {
    path: '/admin',
    name: 'AdminOrganizationOverview',
    component: AdminOrganizationOverview,
    meta: {
      title: 'meta.pageTitles.admin_organization_overview'
    }
  },
  {
    path: '/admin/organization/:id',
    name: 'AdminApplicationOverview',
    component: AdminApplicationOverview,
    meta: {
      title: 'meta.pageTitles.admin_application_overview'
    }
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
    meta: {
      title: 'meta.pageTitles.faq'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      title: 'meta.pageTitles.contact'
    }
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/auth/activate/:uid/:token',
    name: 'ActivateAccount',
    component: ActivateAccount,
    meta: {
      title: 'meta.pageTitles.activate_account'
    }
  },
  {
    path: '/organization',
    name: 'OrganizationDetails',
    component: OrganizationDetail,
    meta: {
      title: 'meta.pageTitles.organization_detail'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'CatchAll',
    redirect: '/'
  }
]

const pathsWhichNeedNoAuthentication = [
  'Home',
  'Imprint',
  'Privacy',
  'UserLogin',
  'RequestPasswordReset',
  'ResetPassword',
  'Conditions',
  'Registration',
  'ActivateAccount',
  'CatchAll'
]

const siteTitle = 'TOTAL-E-QUALITY'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  let name = ''
  if (to.name) {
    name = String(to.name)
  }

  if (pathsWhichNeedNoAuthentication.includes(name)) {
    next()
  } else {
    if (isAuthenticated()) {
      next()
    } else {
      if (to.fullPath.startsWith('/admin')) {
        localStorage.setItem('willLoginIntoAdmin', 'Yes')
      }
      next('/auth')
    }
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = i18n.global.t(String(nearestWithTitle.meta.title)) + ' | ' + siteTitle
  } else {
    document.title = siteTitle
  }
})

router.afterEach((to, from) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window._paq === undefined || to.meta.analyticsIgnore) {
    return
  }

  const url = router.resolve(to.fullPath).href
  const referrerUrl = from && from.fullPath
    ? router.resolve(from.fullPath).href
    : undefined

  if (referrerUrl) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window._paq.push(['setReferrerUrl', referrerUrl])
  }
  if (url) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window._paq.push(['setCustomUrl', url])
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window._paq.push(['setDocumentTitle', document.title])
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window._paq.push(['trackPageView'])
})

export default router
