
import { defineComponent } from 'vue'
import Header from '@/components/base/Header.vue'
import Footer from '@/components/base/Footer.vue'
import Iframe from '@/components/base/Iframe.vue'

export default defineComponent({
  name: 'Faq',
  components: {
    Header,
    Footer,
    Iframe
  }
})
