
import { defineComponent } from 'vue'
import Footer from '@/components/base/Footer.vue'

export default defineComponent({
  name: 'MainFormContainer',
  components: {
    Footer
  },
  props: {
    title: String,
    titleCls: {
      Type: String,
      required: false
    },
    subtitle: String,
    subtitleCls: {
      Type: String,
      required: false
    },
    isJsonForm: {
      type: Boolean,
      default: false
    }
  }
})
