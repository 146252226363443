import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-center mx-auto" }
const _hoisted_2 = ["data-bs-toggle", "data-progress-state-icon-type", "title"]
const _hoisted_3 = ["data-bs-toggle", "data-progress-state-icon-type", "title"]
const _hoisted_4 = ["data-bs-toggle", "data-progress-state-icon-type", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_icon_info = _resolveComponent("b-icon-info")!
  const _component_b_icon_exclamation = _resolveComponent("b-icon-exclamation")!
  const _component_b_icon_check = _resolveComponent("b-icon-check")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["progress-state-icon alert", [_ctx.activeTooltipNotSaved ? 'alert-warning' : 'alert-black-20']]),
      "data-bs-toggle": [_ctx.activeTooltipNotSaved ? 'tooltip' : 'no-tooltip'],
      "data-progress-state-icon-type": _ctx.localFormErrorState.NotSaved,
      title: _ctx.getProgressStateVerbose(_ctx.localFormErrorState.NotSaved)
    }, [
      _createVNode(_component_b_icon_info)
    ], 10, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(["progress-state-icon alert", [_ctx.activeTooltipHasErrors ? 'alert-danger' : 'alert-black-20']]),
      "data-bs-toggle": [_ctx.activeTooltipHasErrors ? 'tooltip' : 'no-tooltip'],
      "data-progress-state-icon-type": _ctx.localFormErrorState.HasErrors,
      title: _ctx.getProgressStateVerbose(_ctx.localFormErrorState.HasErrors)
    }, [
      _createVNode(_component_b_icon_exclamation)
    ], 10, _hoisted_3),
    _createElementVNode("div", {
      class: _normalizeClass(["progress-state-icon alert", [_ctx.activeTooltipWithoutErrors ? 'alert-success' : 'alert-black-20']]),
      "data-bs-toggle": [_ctx.activeTooltipWithoutErrors ? 'tooltip' : 'no-tooltip'],
      "data-progress-state-icon-type": _ctx.localFormErrorState.WithoutErrors,
      title: _ctx.getProgressStateVerbose(_ctx.localFormErrorState.WithoutErrors)
    }, [
      _createVNode(_component_b_icon_check)
    ], 10, _hoisted_4)
  ]))
}