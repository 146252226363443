import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BadgeRound = _resolveComponent("BadgeRound")!

  return (_openBlock(), _createBlock(_component_BadgeRound, {
    label: _ctx.validity === _ctx.OrganizationCertificateValidity.CertificateIsValid ? _ctx.$t('application.validity_states_verbose.valid') : _ctx.validity === _ctx.OrganizationCertificateValidity.CertificateIsInGracePeriod ? _ctx.$t('application.validity_states_verbose.in_grace_period') : _ctx.$t('application.validity_states_verbose.invalid'),
    "at-start": false,
    "data-bs-toggle": "tooltip",
    id: 'tooltip-' + _ctx.id,
    title: _ctx.validity === _ctx.OrganizationCertificateValidity.CertificateIsValid ? _ctx.$t('application.validity_states_verbose.valid') : _ctx.validity === _ctx.OrganizationCertificateValidity.CertificateIsInGracePeriod ? _ctx.$t('application.validity_states_verbose.in_grace_period') : _ctx.$t('application.validity_states_verbose.invalid'),
    class: _normalizeClass([_ctx.validity === _ctx.OrganizationCertificateValidity.CertificateIsValid ? 'bg-success' : _ctx.validity === _ctx.OrganizationCertificateValidity.CertificateIsInGracePeriod ? 'bg-warning' : 'bg-danger'])
  }, null, 8, ["label", "id", "title", "class"]))
}