
import { defineComponent } from 'vue'
import { Application, ApplicationPeriod, ApplicationState } from '@/types/descript_application_portal_rest'
import { errorHelper, getApplicationPeriod, getApplicationTime, getAreaOfCompany, getDate } from '@/utils'
import MainOverviewContainer from '@/components/base/MainOverviewContainer.vue'
import ApplicationStateComponent from '@/components/application/ApplicationStateComponent.vue'
import SearchInput from '@/components/base/SearchInput.vue'
import Dropdown from '@/components/base/Dropdown.vue'

export default defineComponent({
  name: 'AdminApplicationOverview',
  components: {
    MainOverviewContainer,
    ApplicationStateComponent,
    SearchInput,
    Dropdown
  },
  computed: {
    activeLocale: function (): string {
      return this.$i18n.locale
    },
    applicationList: function (): Array<Application> {
      return this.$store.state.allApplications
    },
    periodList: function (): Record<number, ApplicationPeriod> {
      return this.$store.state.applicationPeriods
    }
  },
  data: function () {
    return {
      localApplicationStates: ApplicationState,
      searchTerm: '',
      isLoaded: false,
      organizationId: this.$route.params.id ? Number(this.$route.params.id) : null
    }
  },
  created: async function () {
    localStorage.removeItem('applicationId')
    // removing the current period, when navigating here, so another application will trigger the fetching again
    this.$store.commit('setCurrentApplicationPeriod', null)
    await this.$store.dispatch('getAllApplications', { organization: this.organizationId }).catch(errorHelper(this.$store))
    const periods: Record<number, ApplicationPeriod> = {}
    let period
    for (const application of this.applicationList) {
      if (!(application.application_period in periods)) {
        period = await this.$store.dispatch('getSpecificApplicationPeriod', { period: application.application_period, setCurrent: false })
        periods[application.application_period] = period
      }
    }
    this.$store.commit('setAllApplicationPeriods', periods)
    this.isLoaded = true
  },
  methods: {
    getAreaOfCompany,
    getApplicationTime,
    getDate,
    getApplicationPeriod,
    doSearch: async function (searchTerm: string) {
      this.searchTerm = searchTerm
      await this.$store.dispatch('getAllApplications', {
        searchTerm: searchTerm
      }).catch(errorHelper(this.$store))
    },
    resetSearch: async function () {
      this.searchTerm = ''
      await this.$store.dispatch('getAllApplications').catch(errorHelper(this.$store))
    },
    setApplicationState: async function (applicationId: number, applicationState: ApplicationState) {
      await this.$store.dispatch('setApplicationState', { applicationId: applicationId, state: applicationState }).catch(errorHelper(this.$store))
      await this.$store.dispatch('getAllApplications', {
        searchTerm: this.searchTerm
      }).catch(errorHelper(this.$store))
    }
  }
})
