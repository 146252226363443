import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "form-check mb-3" }
const _hoisted_2 = ["id", "name", "checked"]
const _hoisted_3 = ["for"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      class: _normalizeClass(["form-check-input", _ctx.errorMessage ? ' is-invalid': '']),
      id: 'id_' + _ctx.name,
      name: _ctx.name,
      checked: _ctx.checked,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
    }, null, 42, _hoisted_2),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "form-check-label",
          for: 'id_' + _ctx.name
        }, [
          _createElementVNode("span", { innerHTML: _ctx.label }, null, 8, _hoisted_4)
        ], 8, _hoisted_3))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", { class: "invalid-feedback" }, _toDisplayString(_ctx.errorMessage), 513), [
      [_vShow, !!_ctx.errorMessage]
    ]),
    (_ctx.helpText)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          id: _ctx.helpId,
          class: "form-text"
        }, _toDisplayString(_ctx.helpText), 9, _hoisted_5))
      : _createCommentVNode("", true)
  ]))
}