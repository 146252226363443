import { State } from '@/store/state'
import { AlertType, Filter } from '@/types/internal'
import {
  Application,
  ApplicationAction,
  ApplicationForm,
  ApplicationPeriod, FieldOfAction, Organization
} from '@/types/descript_application_portal_rest'

export const mutations = {
  /**
   * adds a Message Alert which will be displayed at the current view
   *
   * @example
   * this.$store.commit('addImmediateAlert', { msg: MESSAGE, type: AlertType.Danger })
   */
  addImmediateAlert (state: State, payload: { msg: string; type?: AlertType }): void {
    if (payload.type === undefined) {
      payload.type = AlertType.Info
    }

    state.immediateAlerts.push({
      msg: payload.msg,
      type: payload.type
    })
  },
  clearImmediateAlerts (state: State): void {
    // splice with this parameters is the performant way to empty an array
    state.immediateAlerts.splice(0, state.immediateAlerts.length)
  },
  clearAndDismissImmediateAlerts (state: State): void {
    // splice with this parameters is the performant way to empty an array
    state.immediateAlerts.splice(0, state.immediateAlerts.length)
  },
  /**
   * adds an Alert for the next visible view. The function will handle itself, that every unique alert message
   * will only be displayed once
   *
   * @example
   * this.$store.commit('addPendingAlert', { msg: MESSAGE, type: AlertType.Danger })
   */
  addPendingAlert (state: State, payload: { msg: string; type?: AlertType }): void {
    if (payload.type === undefined) {
      payload.type = AlertType.Info
    }

    // A pending alert must only be added if there is not already an exact
    // same message in the list of pending alerts. Adding the same alert could
    // for example happen when multiple requests to the REST api are done and
    // they fail with a non authenticated error.

    let alreadyThere = false
    for (const pendingAlert of state.pendingAlerts) {
      if (pendingAlert.msg === payload.msg && pendingAlert.type === payload.type) {
        alreadyThere = true
      }
    }

    if (!alreadyThere) {
      state.pendingAlerts.push({
        msg: payload.msg,
        type: payload.type
      })
    }
  },
  clearPendingAlerts (state: State): void {
    // splice with this parameters is the performant way to empty an array
    state.pendingAlerts.splice(0, state.pendingAlerts.length)
  },
  /**
   * writes the response data of an application and its organization into the store
   * @param state
   * @param payload
   */
  setCurrentApplicationAndOrganization (state: State, payload: Application): void {
    state.organization = payload.organization
    state.currentApplication = payload
  },
  setApplicationForms (state: State, payload: Array<ApplicationForm>): void {
    state.applicationForms = payload
  },
  setCurrentApplicationPeriod (state: State, payload: ApplicationPeriod): void {
    state.currentApplicationPeriod = payload
  },
  setPeriodOfApplication (state: State, payload: ApplicationPeriod): void {
    state.periodOfApplication = payload
  },
  setAllApplicationPeriods (state: State, payload: Record<number, ApplicationPeriod>): void {
    state.applicationPeriods = payload
  },
  setCurrentApplicationActions (state: State, payload: Array<ApplicationAction>): void {
    state.applicationActions = payload
  },
  deleteApplicationAction (state: State, payload: ApplicationAction): void {
    let indexToRemove = -1
    for (let index = 0; index < state.applicationActions.length; index++) {
      if (state.applicationActions[index].id === payload.id) {
        indexToRemove = index
        break
      }
    }
    if (indexToRemove >= 0) {
      state.applicationActions.splice(indexToRemove, 1)
    }
  },
  setFieldsOfAction (state: State, payload: Array<FieldOfAction>): void {
    state.fieldsOfAction = payload
  },
  setApplicationList (state: State, payload: Array<Application>): void {
    state.allApplications = payload
  },
  ensureOrganizationList (state: State, listKey: string): void {
    if (!Object.prototype.hasOwnProperty.call(state.allOrganizations, listKey)) {
      state.allOrganizations[listKey] = {
        searchTerm: '',
        filters: [] as Array<Filter>,
        instances: [] as Array<Organization>,
        urlPart: ''
      }
    }
  },
  setOrganizationList (state: State, payload: { listKey: string, allOrganizations: Array<Organization> }): void {
    const organizations = state.allOrganizations[payload.listKey]

    // splice with this parameters is the performant way to empty an array
    organizations.instances.splice(0, organizations.instances.length)
    for (const meetingTemplate of payload.allOrganizations) {
      organizations.instances.push(
        meetingTemplate)
    }
  },
  addOrganizationsFilter (state: State, payload: { listKey: string, filter: Filter }): void {
    const meetingTemplates = state.allOrganizations[payload.listKey]
    let found = -1

    for (let index = 0; index < meetingTemplates.filters.length; index++) {
      if (payload.filter.group === meetingTemplates.filters[index].group) {
        found = index
        break
      }
    }

    if (found >= 0) {
      meetingTemplates.filters.splice(found, 1)
    }

    meetingTemplates.filters.push(payload.filter)
  },
  removeOrganizationsFilter (state: State, payload: { listKey: string, filter: Filter }): void {
    const meetingTemplates = state.allOrganizations[payload.listKey]
    let found = -1

    for (let index = 0; index < meetingTemplates.filters.length; index++) {
      if (payload.filter.key === meetingTemplates.filters[index].key &&
        payload.filter.value === meetingTemplates.filters[index].value) {
        found = index
        break
      }
    }

    if (found >= 0) {
      meetingTemplates.filters.splice(found, 1)
    }
  },
  setAvailableOrganizationsFilter (state: State, payload: Array<Filter>): void {
    state.availableOrganizationFilters = payload
  },
  resetAdminOrganizationFilter (state: State, listKey: string): void {
    const organizations = state.allOrganizations[listKey]

    organizations.filters.splice(0, organizations.filters.length)
  }
}
