
import { defineComponent, PropType } from 'vue'
import { Filter, FilterGroupData } from '@/types/internal'
import Dropdown from '@/components/base/Dropdown.vue'
import { availableFilterByGroup, isActiveFilter } from '@/utils'

export default defineComponent({
  name: 'FilterBar',
  components: { Dropdown },
  props: {
    identifier: {
      type: String,
      required: true
    },
    availableFilters: {
      type: Array as PropType<Array<Filter>>,
      required: true
    },
    activeFilters: {
      type: Object as PropType<Array<Filter>>,
      required: true
    }
  },
  data: function () {
    return {
      idBase: 'js-filter-bar-' + this.identifier
    }
  },
  computed: {
    availableFiltersByGroup: function (): Array<FilterGroupData> {
      return availableFilterByGroup(this.availableFilters, this.activeFilters)
    }
  },
  methods: {
    isActive: function (filter: Filter) {
      return isActiveFilter(filter, this.activeFilters)
    },
    handleFilter: function (filter: Filter) {
      if (this.isActive(filter)) {
        this.$emit('removeFilter', filter)
      } else {
        this.$emit('addFilter', filter)
      }
    }
  },
  emits: [
    'removeFilter',
    'addFilter'
  ]
})
