import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-labelledby"]
const _hoisted_2 = ["id", "onClick"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableFiltersByGroup, (keyData, index) => {
    return (_openBlock(), _createBlock(_component_Dropdown, {
      key: keyData,
      id: _ctx.idBase + '-' + index,
      class: _normalizeClass([keyData.activeFilter ? 'btn-outline-dark': 'btn-outline-light', "dropdown-toggle text-decoration-none btn-outline-light"]),
      role: "button",
      "aria-expanded": "false",
      "container-style": "col-3"
    }, _createSlots({
      main: _withCtx(() => [
        _createElementVNode("ul", {
          class: "dropdown-menu dropdown-menu-end",
          "aria-labelledby": _ctx.idBase + '-' + index
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(keyData.filters, (filter) => {
            return (_openBlock(), _createElementBlock("li", { key: filter }, [
              _createElementVNode("a", {
                href: "",
                id: _ctx.idBase + '-' + filter.key + '-' + filter.value,
                class: _normalizeClass(["dropdown-item d-flex align-items-center", {'active': _ctx.isActive(filter)}]),
                onClick: _withModifiers(($event: any) => (_ctx.handleFilter(filter)), ["prevent"])
              }, [
                (_ctx.isActive(filter))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('links.reset_filter')) + ": ", 1))
                  : _createCommentVNode("", true),
                (filter.label)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(filter.label), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(filter.value), 1))
              ], 10, _hoisted_2)
            ]))
          }), 128))
        ], 8, _hoisted_1)
      ]),
      _: 2
    }, [
      (keyData.activeFilter)
        ? {
            name: "label",
            fn: _withCtx(() => [
              _createTextVNode(_toDisplayString(keyData.verbose) + ": ", 1),
              (keyData.activeFilter.label)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(keyData.activeFilter.label), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(keyData.activeFilter.value), 1)
                  ], 64))
            ])
          }
        : {
            name: "label",
            fn: _withCtx(() => [
              _createTextVNode(_toDisplayString(keyData.verboseNotActive), 1)
            ])
          }
    ]), 1032, ["id", "class"]))
  }), 128))
}