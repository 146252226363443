import { ActionContext } from 'vuex'
import { State } from '@/store/state'
import {
  ActionFile,
  Application,
  ApplicationAction,
  ApplicationForm, ApplicationFormFile,
  ApplicationPeriod, ApplicationState, FieldOfAction, Organization
} from '@/types/descript_application_portal_rest'
import http from '@/http-common'
import { Filter } from '@/types/internal'
import { getApplicationPeriod } from '@/utils'
import { i18n } from '@/i18n'

export const actions = {
  async getCurrentApplicationPeriod (context: ActionContext<State, State>): Promise<ApplicationPeriod> {
    const response = await http.get('application_portal/application_period/get_current_application_period/')
    context.commit('setCurrentApplicationPeriod', response.data)
    return response.data
  },
  async getSpecificApplicationPeriod (context: ActionContext<State, State>, payload: {
    period: number,
    setCurrent?: boolean,
    setPeriodOfApplication?: boolean
  }): Promise<ApplicationPeriod> {
    const response = await http.get(`application_portal/application_period/${payload.period}/`)
    if (payload.setCurrent) {
      context.commit('setCurrentApplicationPeriod', response.data)
    }
    if (payload.setPeriodOfApplication) {
      context.commit('setPeriodOfApplication', response.data)
    }
    return response.data
  },
  /**
   * calls the rest api and fills the store with the current application and organization of the user
   * @param context
   * @param applicationId
   */
  async getLatestApplication (context: ActionContext<State, State>, applicationId: number): Promise<Application | null> {
    const response = await http.get(`application_portal/application/${applicationId}/`)
    context.commit('setCurrentApplicationAndOrganization', response.data)
    if (!context.state.currentApplicationPeriod || !context.state.currentApplicationPeriod.id) {
      await context.dispatch('getSpecificApplicationPeriod', {
        period: response.data.application_period,
        setCurrent: true
      })
    }
    if (!context.state.periodOfApplication || !context.state.periodOfApplication.id !== response.data.application_period) {
      await context.dispatch('getSpecificApplicationPeriod', {
        period: response.data.application_period,
        setPeriodOfApplication: true
      })
    }
    return response.data
  },
  async getFormsForApplication (context: ActionContext<State, State>, payload: number): Promise<Array<ApplicationForm>> {
    let application
    if (!context.state.currentApplication || !context.state.currentApplication.id) {
      application = await context.dispatch('getLatestApplication')
    } else {
      application = context.state.currentApplication
    }
    const params = {
      application: application.id
    }
    const response = await http.get('application_portal/application_form/', { params: params })
    context.commit('setApplicationForms', response.data)
    return response.data as Array<ApplicationForm>
  },
  async getCurrentActions (context: ActionContext<State, State>, payload?: {
    searchTerm?: string
  }): Promise<Array<ApplicationAction>> {
    let application
    if (!context.state.currentApplication || !context.state.currentApplication.id) {
      application = await context.dispatch('getLatestApplication')
    } else {
      application = context.state.currentApplication
    }
    const params: {
      application: number,
      search?: string
    } = {
      application: application.id
    }
    if (payload && payload.searchTerm) {
      params.search = payload.searchTerm
    }
    const response = await http.get('application_portal/action/', { params: params })
    context.commit('setCurrentApplicationActions', response.data)
    return response.data as Array<ApplicationAction>
  },
  async deleteApplicationAction (context: ActionContext<State, State>, applicationAction: ApplicationAction) {
    context.commit('deleteApplicationAction', applicationAction)
    await http.delete(`/application_portal/action/${applicationAction.id}/`)
  },
  async createApplicationAction (context: ActionContext<State, State>, actionData: Record<string, any>): Promise<ApplicationAction> {
    const response = await http.post('/application_portal/action/', JSON.stringify(actionData, null, 2))
    return response.data
  },
  async getSpecificApplicationAction (context: ActionContext<State, State>, applicationActionId: number): Promise<ApplicationAction> {
    const response = await http.get(`/application_portal/action/${applicationActionId}/`)
    return response.data
  },
  async saveApplicationActionData (context: ActionContext<State, State>, payload: {
    applicationActionId: number,
    actionData: Record<string, any>
  }) {
    const response = await http.patch(`/application_portal/action/${payload.applicationActionId}/`, JSON.stringify(payload.actionData, null, 2))
    return response.data
  },
  async getFieldOfActionForApplication (context: ActionContext<State, State>, applicationId?: number): Promise<Array<FieldOfAction>> {
    let application
    if (!context.state.currentApplication || !context.state.currentApplication.id) {
      application = await context.dispatch('getLatestApplication')
    } else {
      application = context.state.currentApplication
    }
    const params = {
      application: application.id
    }
    const response = await http.get('application_portal/field_of_action/', { params: params })
    context.commit('setFieldsOfAction', response.data)
    return response.data
  },
  async getSpecificFieldOfAction (context: ActionContext<State, State>, fieldOfActionId: number): Promise<FieldOfAction> {
    const response = await http.get(`application_portal/field_of_action/${fieldOfActionId}/`)
    return response.data
  },
  async getApplicationForm (context: ActionContext<State, State>, payload: number): Promise<ApplicationForm> {
    const response = await http.get(`/application_portal/application_form/${payload}/`)
    return response.data
  },
  async storeApplicationFormAnswers (context: ActionContext<State, State>, payload: {
    applicationFormId: number,
    answers: JSON,
    errors: JSON | null
  }) {
    await http.patch(
      `/application_portal/application_form/${payload.applicationFormId}/`,
      JSON.stringify({
        answers: payload.answers,
        errors: payload.errors
      }, null, 2))
  },
  async storeFieldOfActionFormAnswers (context: ActionContext<State, State>, payload: {
    fieldOfActionId: number,
    answers: JSON,
    actions: Array<number>,
    errors: JSON | null
  }) {
    await http.patch(
      `/application_portal/field_of_action/${payload.fieldOfActionId}/`,
      JSON.stringify({
        answers: payload.answers,
        actions: payload.actions,
        errors: payload.errors
      }, null, 2))
  },
  async getApplicationActionFiles (context: ActionContext<State, State>, applicationActionId: number): Promise<Array<ActionFile>> {
    const params = {
      action: applicationActionId
    }
    const response = await http.get('/application_portal/actionfile/', { params: params })
    return response.data
  },
  async deleteApplicationActionFile (context: ActionContext<State, State>, applicationActionFileId: number) {
    await http.delete(`/application_portal/actionfile/${applicationActionFileId}/`)
  },
  async updateApplicationActionFile (context: ActionContext<State, State>, payload: {
    applicationActionId: number,
    actionData: Record<string, any>
  }) {
    const response = await http.patch(`/application_portal/actionfile/${payload.applicationActionId}/`, JSON.stringify(payload.actionData, null, 2))
    return response.data
  },
  async uploadActionFile (context: ActionContext<State, State>, payload: FormData) {
    const response = await http.post('/application_portal/actionfile/', payload, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    return response.data
  },
  async setApplicationState (context: ActionContext<State, State>, payload: {
    applicationId: number,
    state: ApplicationState
  }): Promise<Application> {
    const response = await http.patch(`/application_portal/application/${payload.applicationId}/`, JSON.stringify({ state: payload.state }))
    context.commit('setCurrentApplicationAndOrganization', response.data)
    return response.data
  },
  async getAllApplications (context: ActionContext<State, State>, payload?: {
    searchTerm: string,
    currentPeriod: number,
    organization?: number
  }): Promise<Array<Application>> {
    const params: {
      // eslint-disable-next-line camelcase
      application_period?: number,
      search?: string,
      organization?: number
    } = {}
    if (payload && payload.currentPeriod) {
      params.application_period = payload.currentPeriod
    }
    if (payload && payload.searchTerm) {
      params.search = payload.searchTerm
    }
    if (payload && payload.organization) {
      params.organization = payload.organization
    }
    const response = await http.get('application_portal/application/', { params: params })
    context.commit('setApplicationList', response.data)
    return response.data[0]
  },
  async requestApplication (context: ActionContext<State, State>, payload: {
    currentApplicationPeriodId: number,
    applicationToCopyFrom: number
  }): Promise<Application> {
    const params: {
      // eslint-disable-next-line camelcase
      application_period_id: number,
      // eslint-disable-next-line camelcase
      application_id?: number
    } = { application_period_id: payload.currentApplicationPeriodId }
    if (payload.applicationToCopyFrom) {
      params.application_id = payload.applicationToCopyFrom
    }
    const response = await http.post('application_portal/application/create_full_application/', JSON.stringify(params))
    context.commit('setCurrentApplicationAndOrganization', response.data)
    return response.data
  },
  async getOrganization (context: ActionContext<State, State>): Promise<Organization> {
    const response = await http.get('/application_portal/organization/')
    return response.data[0]
  },
  async saveOrganizationData (context: ActionContext<State, State>, payload: {
    organizationId: number,
    formData: Record<string, any>
  }) {
    const response = await http.patch(`/application_portal/organization/${payload.organizationId}/`, payload.formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    return response.data
  },
  async getFormFiles (context: ActionContext<State, State>, applicationFormId: number): Promise<ApplicationFormFile> {
    const params = {
      application_form: applicationFormId
    }
    const response = await http.get('/application_portal/applicationformfile/', { params: params })
    return response.data
  },
  async deleteApplicationFormFile (context: ActionContext<State, State>, applicationFormId: number): Promise<void> {
    await http.delete(`/application_portal/applicationformfile/${applicationFormId}/`)
  },
  async uploadApplicationFormFile (context: ActionContext<State, State>, payload: FormData): Promise<ApplicationFormFile> {
    const response = await http.post('/application_portal/applicationformfile/', payload, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    return response.data
  },
  async getAllOrganizations (context: ActionContext<State, State>, listKey: string): Promise<Array<Organization>> {
    context.commit('ensureOrganizationList', listKey)

    const organizations = context.state.allOrganizations[listKey]
    const params: {
      // eslint-disable-next-line camelcase
      application_period?: string,
      type?: string | Array<string>,
    } = {}

    if (organizations.filters.length > 0) {
      for (const filter of organizations.filters) {
        if (filter.key === 'application_period') {
          params.application_period = filter.value
        }
        if (filter.key === 'type') {
          if (filter.value === '1') {
            params.type = ['1', '5', '6']
          } else {
            params.type = [filter.value]
          }
        }
      }
    }

    const response = await http.get('/application_portal/organization/', {
      params: params
    })
    context.commit('setOrganizationList', {
      listKey: listKey,
      allOrganizations: response.data
    })
    return response.data
  },
  async addAdminOrganizationFilter (
    context: ActionContext<State, State>,
    payload: { listKey: string, filter: Filter, onlyOnListInit: boolean }
  ): Promise<Array<Organization>> {
    if (!payload.onlyOnListInit ||
      !Object.prototype.hasOwnProperty.call(context.state.allOrganizations, payload.listKey)) {
      context.commit('ensureOrganizationList', payload.listKey)
      context.commit('addOrganizationsFilter', payload)
    }

    return context.dispatch('getAllOrganizations', payload.listKey)
  },
  async removeAdminOrganizationFilter (
    context: ActionContext<State, State>,
    payload: { listKey: string, filter: Filter }
  ): Promise<Array<Organization>> {
    context.commit('ensureOrganizationList', payload.listKey)
    context.commit('removeOrganizationsFilter', payload)
    return context.dispatch('getAllOrganizations', payload.listKey)
  },
  async resetAdminOrganizationFilter (context: ActionContext<State, State>, listKey: string): Promise<Array<Organization>> {
    context.commit('ensureOrganizationList', { listKey: listKey })
    context.commit('resetAdminOrganizationFilter', listKey)
    return context.dispatch('getAllOrganizations', listKey)
  },
  async getAvailableOrganizationFilter (context: ActionContext<State, State>): Promise<Array<Filter>> {
    const allApplicationPeriods = await http.get('application_portal/application_period/')
    const availableOrganizationFilter: Array<Filter> = []

    for (const period of allApplicationPeriods.data) {
      availableOrganizationFilter.push(
        {
          group: 'application_period',
          key: 'application_period',
          value: period.id.toString(),
          label: getApplicationPeriod(period)
        }
      )
    }
    const labelDict : Record<number, string> = {
      1: i18n.global.t('text.orga_type_science'),
      2: i18n.global.t('text.orga_type_admin'),
      3: i18n.global.t('text.orga_type_industry'),
      4: i18n.global.t('text.orga_type_association')
    }
    for (let i = 1; i <= 4; i++) {
      availableOrganizationFilter.push(
        {
          group: 'type',
          key: 'type',
          value: i.toString(),
          label: labelDict[i]
        }
      )
    }

    context.commit('setAvailableOrganizationsFilter', availableOrganizationFilter)

    return availableOrganizationFilter
  }
}
