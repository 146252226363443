<template>
  <nav class="page-main-nav navbar navbar-expand navbar-light bg-white"
       :class="[position ? position + ' shadow' : '']">
    <div class="container">
      <router-link :to='isAdmin? "/admin": "/overview"'
                   class="navbar-brand">
        <img alt="Logo: TOTAL E-QUALITY"
             src="@/assets/images/TEQ_logo.svg">
      </router-link>
      <ul class="navbar-nav me-auto">
        <li v-if="isAdmin"
            class="nav-item me-6">
          <router-link to="/admin"
                       class="nav-link"
                       active-class="active">
            {{ $t('meta.pageTitles.admin_application_overview') }}
          </router-link>
        </li>
        <li v-if="!isAdmin"
            class="nav-item me-6">
          <router-link to="/conditions_of_participation"
                       class="nav-link"
                       active-class="active">
            {{ $t('meta.pageTitles.conditions_of_participation') }}
          </router-link>
        </li>
        <li v-if="(!isAdmin || applicationIdIsSet) && application"
            class="nav-item me-6">
          <router-link :to="`/application/${application.id}`"
                       class="nav-link"
                       active-class="active">
            {{ $t('meta.pageTitles.overview') }} <span v-if="isAdmin && applicationIdIsSet && application" class="text-muted small">({{ application.organization.name }})</span>
          </router-link>
        </li>
        <li v-if="!isAdmin"
            class="nav-item me-6">
          <router-link to="/faq"
                       class="nav-link"
                       active-class="active">
            {{ $t('meta.pageTitles.faq') }}
          </router-link>
        </li>
        <li v-if="!isAdmin"
            class="nav-item me-6">
          <router-link to="/contact"
                       class="nav-link"
                       active-class="active">
            {{ $t('meta.pageTitles.contact') }}
          </router-link>
        </li>
        <li v-if="!isAdmin"
            class="nav-item">
          <a href="https://www.total-e-quality.de"
             target="_blank"
             rel="noopener"
             class="nav-link">
            {{ $t('links.goto_website') }}
          </a>
        </li>
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item border-end border-black-10 me-3 pe-3">
          <router-link to="/user_settings"
                       class="nav-link"
                       active-class="active">
            {{ $t('meta.pageTitles.settings_overview') }}
          </router-link>
        </li>
        <li class="nav-item">
          <Logout/>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from 'vue'
import Logout from '@/components/auth/Logout'

export default defineComponent({
  name: 'Header',
  components: { Logout },
  props: {
    position: {
      type: String,
      default: 'sticky-top'
    }
  },
  data: function () {
    return {
      isAdmin: !!localStorage.getItem('isAdmin')
    }
  },
  computed: {
    application () {
      return this.$store.state.currentApplication
    },
    applicationIdIsSet () {
      return !!this.$store.state.currentApplication && !!this.$store.state.currentApplication.id
    }
  }
})
</script>

<style scoped>
</style>
