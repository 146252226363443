/**
 * Types of possible Alerts
 * @enum {string}
 */
export enum AlertType {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark'
}

export interface Message {
  msg: string,
  type: AlertType
}

export enum FormErrorState {
  NotSaved = 'warning',
  HasErrors = 'danger',
  WithoutErrors = 'success'
}

export interface Filter {
  group: string,
  key: string,
  value: string,
  label?: string
}

export interface InstancesWithSearchAndFilterState<T> {
  searchTerm: string,
  filters: Array<Filter>,
  instances: Array<T>,
  urlPart: string
}

export interface FilterGroupData {
  group: string,
  verbose: string,
  verboseNotActive: string
  filters: Array<Filter>,
  activeFilter: Filter | null
}
