
import { defineComponent } from 'vue'
import { LOCALES } from '@/i18n/locales.ts'

export default defineComponent({
  name: 'LocaleChooser',
  data: () => ({
    LOCALES
  }),
  computed: {
    activeLocale (): string {
      return this.$i18n.locale
    }
  },
  methods: {
    onChange (event: Event) {
      if (event && event.target) {
        const newLocale = (event.target as HTMLSelectElement).value
        localStorage.setItem('currentSelectedLanguage', newLocale)
      }
    }
  }
})
