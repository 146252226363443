
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      default: ''
    }
  }
})
