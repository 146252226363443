
import { defineComponent } from 'vue'
import Footer from '@/components/base/Footer.vue'
import Iframe from '@/components/base/Iframe.vue'
import Header from '@/components/base/Header.vue'

export default defineComponent({
  name: 'Imprint',
  components: {
    Header,
    Footer,
    Iframe
  },
  data: function () {
    return {
      isLoggedIn: !!localStorage.getItem('authToken')
    }
  }
})
