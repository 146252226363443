
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'

export default defineComponent({
  props: {
    label: String,
    name: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      default: 120
    },
    value: String,
    elementStyle: String,
    labelStyle: String,
    helpText: String,
    placeholder: String
  },
  setup (props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta
    } = useField(props.name, undefined, {
      initialValue: props.value
    })

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta
    }
  },
  computed: {
    helpId (): string {
      return this.name + 'Help'
    }
  }
})
